import React, { PureComponent } from 'reactn';
import { TextBox } from 'rc-easyui';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import tabController from '../classes/tabController';
import shortid from "shortid";



class RoomListBoxes extends PureComponent {

  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.tabController = new tabController(this.client);


    this.state = {
      data: [],
      search: "",
      rendered: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.global.selectedTab === 'roomList') {
      if (this.state.rendered === false) {
        this.setState({
          rendered: true,
        });
        this.client.showLoading();
        setTimeout(() => this.client.hideLoading(), 300);
      }
    }
  }

  openRoom(node) {
    if (this.roomOpened(node.id) === true) {
      this.client.tabController.selectRoomTab(node.id);
    } else {
      this.client.sendMessage("room_join\x00" + node.id);
    }
  }

  roomOpened(roomid) {
    let data = this.client.state.data.slice();
    const element = data.find((value) => {
      return value.content === roomid;
    })
    return typeof element === "object" ? true : false;
  }

  formChange(value, name) {
        if (name === "search") {
            this.setState({
                search: value
            });
        }
  }   

  render() {
    if (this.state.rendered === true) {
      if (this.global.selectedTab === 'roomList') {
          if (this.state.search === "") {

                    return (
                        <div id="roomListBoxes" className="chatContainerFull" >                      
                        <h2 className="moduleTitle"><div className="moduleIcon icon-rooms"></div> Szobák listája</h2>

                        <div className="roomListContainer">
                            <div className="search"><TextBox className="roomListSearch" value={this.state.search} onChange={(e) => this.formChange(e, "search")} style={{ marginBottom: '10px', width: '100%' }}
                                placeholder="Keresés..."            
                                />
                            </div>
                            <Scrollbar noScrollX={true}>         
                              {this.global.rooms.map((rooms, index) => {
                                  let key = shortid.generate();
                                  return (                
                                  <div className="rooms" key={key}>
                                  <h3> {rooms.text}</h3>

                                          {rooms.children.map((children, index) => {
                                              let key2 = shortid.generate();

                                              let classes;
                                              if (this.roomOpened(children.cls) === true) {
                                                  classes = `room opened`;
                                              } else {
                                                  classes = `room closed`;
                                              }

                                              return <div className={classes} key={key2} onClick={(e) => this.openRoom(children)}> 
                                              
                                              <div className="left">
                                              <span className={`${children.iconCls} icon`}></span>
                                              </div>

                                              <div className="right">
                                              <span className="text">{children.text}</span>  
                                              <span className="usercount">({children.usercount} felhasználó)</span>
                                              </div>
                                              </div>

                                          })}

                                  </div>
                                  )
                              })}
                            </Scrollbar>
                        </div>
                        </div>
                    );

                } else {

                    const searchRegex = new RegExp(this.state.search, 'ig');
                    let found = false;
                    return (
                        <div id="roomListBoxes" className="chatContainerFull" >
                                
                        <h2 className="moduleTitle"><div className="moduleIcon icon-rooms"></div> Szobák listája</h2>

                        <div className="roomListContainer rooms">
                        
                        <Scrollbar noScrollX={true}>

                            <div className="search"><TextBox className="roomListSearch" value={this.state.search} onChange={(e) => this.formChange(e, "search")} style={{ marginBottom: '10px', width: '100%' }}
                                placeholder="Keresés..."            
                                />
                            </div>

                            <h3>Keresés a szobák között</h3>
                            {this.global.rooms.map((rooms, index) => {
                                let key = shortid.generate();
                                return (         

                                <div className="room-found" key={key}>
                                    {rooms.children.map((children, index) => {

                                        if(children.text.match(searchRegex)) {
                                        found = true;
                                        let classes;
                                        if (this.roomOpened(children.cls) === true) {
                                            classes = `room opened`;
                                        } else {
                                            classes = `room closed`;
                                        }
                                    
                                        let key2 = shortid.generate();
                                        return <div className={classes} key={key2} onClick={(e) => this.openRoom(children)}> 
                                        
                                        <div className="left">
                                        <span className={`${children.iconCls} icon`}></span>
                                        </div>

                                        <div className="right">
                                        <span className="text">{children.text}</span>  
                                        <span className="usercount">({children.usercount} felhasználó)</span>
                                        </div>
                                        </div>
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                                )
                            })}

                            {found === false &&
                                <p className="not-found">Nincs találat!</p>
                            }

                            </Scrollbar>
                        </div>
                        </div>
                    );
                }
      } else {
        return null;
      }
    } else {
      return <div>{this.global.selectedTab}</div>;
    }
  }

}
export default RoomListBoxes;