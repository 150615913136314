import React, { PureComponent } from 'reactn';
import { Tree, TextBox } from 'rc-easyui';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import tabController from '../classes/tabController';

class RoomList extends PureComponent {

  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.tabController = new tabController(this.client);

    this.renderNode = this.renderNode.bind(this);
    this.state = {
      data: [],
      selection: null,
      rendered: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.global.selectedTab === 'roomList') {
      if (this.state.rendered === false) {
        this.setState({
          rendered: true,
        });
        this.client.showLoading();
        setTimeout(() => this.client.hideLoading(), 300);
      }
    }
  }

  openRoom(node) {
    if (this.roomOpened(node.id) === true) {
      this.client.tabController.selectRoomTab(node.id);
    } else {
      this.client.sendMessage("room_join\x00" + node.id);
    }
  }

  roomOpened(roomid) {
    let data = this.client.state.data.slice();
    const element = data.find((value) => {
      return value.content === roomid;
    })
    return typeof element === "object" ? true : false;
  }

  handleSearch(value) {
    this.tree.doFilter(value);   
  }

  renderNode({ node }) {
    this.setState({searchNotFound: false}); 
    let classes = node.cls;
    if (this.roomOpened(node.cls) === true) {
      classes = `${node.cls} opened`;
    }

    if (node.children && node.children.length) {
      return (
        <span className={classes}>
          {node.text}
        </span>
      )
    } else {
      let count = <span className="usercount">({node.usercount} felhasználó)</span>
      return (
        <span className={classes}>
          {node.text}
          {count}
        </span>
      )
    }
  }

  render() {
    if (this.state.rendered === true) {
      if (this.global.selectedTab === 'roomList') {
        return (
          <div id="chatRoomsTab">

          <h2 className="moduleTitle"><div className="moduleIcon icon-rooms"></div> Szobák listája</h2>

  

            <div className="roomListContainer">
              <TextBox className="roomListSearch" style={{ marginBottom: '10px', width: '100%' }}
                placeholder="Keresés..."
                onChange={this.handleSearch.bind(this)}
              />
              <Scrollbar noScrollX={true}>
                {this.global.reconnect === false &&
                  <Tree data={this.global.rooms} ref={ref => this.tree = ref} render={this.renderNode} cascadeCheck={false} animate={true} selectLeafOnly={true} onNodeClick={this.openRoom.bind(this)} ></Tree>
                }
              </Scrollbar>
            </div>

          </div>
        );
      } else {
        return null;
      }
    } else {
      return <div>{this.global.selectedTab}</div>;
    }
  }

}
export default RoomList;