import React, { PureComponent, setGlobal, getGlobal } from 'reactn';
import roomController from '../classes/roomController';
import RoomMessages from './RoomMessages';

// import 'emoji-mart/css/emoji-mart.css'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data/sets/14/twitter.json';

import EmojiConverter from 'neato-emoji-converter';
import { MentionsInput, Mention } from 'react-mentions'
import autosize from "autosize";
import { isMobileOnly } from "react-device-detect";

//import {
//  isIOS,
//} from "react-device-detect";

import clientStorage from '../classes/clientStorage'

class Private extends PureComponent {

  constructor(props) {
    super(props);

    this.client = clientStorage.id;
    this.privateID = props.privateID;
    this.privateName = props.privateName;
    this.state = {
      data: [],
      message: "",
      focused: 'focusout',
      showPicker: false,
      showRoomUserlist: true,
      hideNav: 0,
      name: '',
    }
    this.roomController = new roomController(this.client);

    this._handleOnInputFocus = this._handleOnInputFocus.bind(this)
    this._handleOnInputFocusOut = this._handleOnInputFocusOut.bind(this)
    this.converter = new EmojiConverter();
    this.scrollDown = true;
    this.privateMessagesRef = React.createRef(); 
    this.themeMode = getGlobal().themeMode;
    this.modernView = getGlobal().user.modernView;
  }


  componentDidMount() {
    this.getPrivateName();
    autosize(this.textarea);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.message !== prevState.message) {
      this.onInputSizeChange();
    }    
  }

  formChange(event, name) {
    if (name === 'message') {    
      this.setState({
        message: event.target.value
      });
    }
  }

  onInputSizeChange(e) {
    let newStyle = 'calc(100% - '+(this.chatroomInput.clientHeight + 10)+'px)';
    if (this.roomMessagesDiv.style.height !== newStyle) {
      this.roomMessagesDiv.style.height= newStyle;
      setTimeout(() => this.privateMessagesRef.scrollBottom(), 0);
    }
  }

  closeRoom() {
    this.client.sendMessage('private_part\x00' + this.privateID);
  }

  showRoomUserlistInit() {
    var messageContainer = document.querySelectorAll('.chatroom .chatroom-messages');
    var index = 0, length = messageContainer.length;
    if (this.global.showRoomUserlist === true) {
      for (; index < length; index++) {
        messageContainer[index].style.width = 'calc(100% - 200px)';
      }
    } else {
      for (; index < length; index++) {
        messageContainer[index].style.width = '100%';
      }
    }
  }

  showPicker() {
    var show = false;
    if (this.state.showPicker === true) {
      show = false;
    } else {
      show = true;
    }
    this.setState({
      showPicker: show
    });
  }

  hidePicker() {
    this.setState({
      showPicker: false
    });
  }

  setInputDefaultHeight() {
   // this.textarea.style.height = "34px";
  }
  
  _handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (isMobileOnly) {
        this.setState({
          message: this.state.message+"\n",
        });
      } else {
        this.sendMessage();
      }
    }
  };

  sendMessage() {
    if (this.state.message !== '') {
      this.isFreezedPrivate(this.privateID);
      this.client.sendMessage('privatemsg\x00' + this.privateID + '\x00' + this.converter.replaceUnicode(this.state.message));
      this.setState({
        message: '',
        showPicker: false
      });
      this.textarea.focus();
    }    
  }

  _handleKeyDownTopic = (e) => {
    if (e.key === 'Enter') {
      this.client.sendMessage('chgtopic\x00' + this.privateID + '\x00' + e.target.value);
    }
  }

  _handleOnInputFocus() {
    this.setState({
      focused: 'focusin',
      showPicker: false
    });

    //setTimeout(() => this.scrollBar.scrollToBottom(),50);
  }

  _handleOnInputFocusOut() {
    this.setState({
      focused: 'focusout'
    });
  }

  getPrivateName() {
    let data = this.client.state.data;
    data.map((tab, index) => {
      if (tab.content === this.privateID) {
        this.setState({
          name: tab.title
        });
      }
      return true;
    });
  }

  addEmoji = e => {
    let emoji = e.native;
    this.setState({
      message: this.state.message + emoji
    });
  };

  isFreezedPrivate(privateID) {
		let data = this.global.freezedPrivates.slice();
    const element = data.find((value) => {
      return value.id === privateID;
    })
    return typeof element === "object" ? true : false;
  }

  handleOpenModeratorModal() {
    setGlobal({ 
      moderatorModalOpened: true,
      moderatorModalOpenedUser: this.privateName,
    });
  }

  render() {
      let mainClass = 'private chatroom';
      let classChatroomMessages;
      if (this.state.focused === 'focusin') {
        classChatroomMessages = 'chatroom-messages privatebg chatroomFocused';
      } else {
        classChatroomMessages = 'chatroom-messages privatebg';
      }
      let messageInput ="messageInput "+this.state.focused;
      let online = this.global.users.filter(arr => arr.name === this.privateName);
      
      return (
        <div className={mainClass}>
          <div className={classChatroomMessages} ref={(e) => (this.roomMessagesDiv = e)} onClick={() => this.hidePicker()}>
            {online.length > 0 &&
            <div className="private-status-online"><div className="text">{this.privateName} jelenleg elérhető</div></div>
            }
            {online.length === 0 &&
            <div className="private-status-offline"><div className="text">{this.privateName} jelenleg nem érhető el, értesítést küldünk neki az üzeneteidről</div></div>
            }
            {/* <PrivateMessages privateID={this.privateID}  privateName={this.privateName} ref={(e) => (this.privateMessagesRef = e)} /> */}

            <RoomMessages
              roomID={this.privateID}
              mode="private"
              ref={(e) => (this.privateMessagesRef = e)}
            />

          </div>
          <div className="chatroom-input" ref={c => (this.chatroomInput = c)}>
            {this.state.showPicker === true &&
              <div className="emojiSelector">
                <Picker
                  data={data}
                  onEmojiSelect={this.addEmoji}
                  emojiSize={18}
                  set="twitter"
                  title=""
                  i18n={{
                    search: "Keresés",
                    notfound: "Nincs találat!",
                    clear: "Törlés",
                    categories: {
                      search: "Keresési eredmények",
                      recent: "Gyakran használt",
                      smileys: "Hangulatjelek",
                      people: "Hangulatjelek",
                      nature: "Állatok és természet",
                      foods: "Étel és ital",
                      activity: "Tevékenység",
                      places: "Helyek és utazás",
                      objects: "Tárgyak",
                      symbols: "Szimbólumok",
                      flags: "Zászlók",
                      custom: "Egyéb",
                    },
                    skins: {
                      choose: "Válassz",
                      tone: "Bőrszín",
                    },
                  }}
                />              
              </div>
            }
            <button className="showPicker" onClick={() => this.showPicker()} />
            <MentionsInput inputRef={c => (this.textarea = c)} className={messageInput} placeholder="Üzenet küldése..." value={this.state.message}  onKeyDown={this._handleKeyDown} onFocus={this._handleOnInputFocus} onBlur={this._handleOnInputFocusOut} onChange={(e) => this.formChange(e, 'message')}>
                    <Mention
                      trigger="@"
                      data={[]}
                    />
            </MentionsInput>
            <button className="sendMessage" onClick={() => this.sendMessage()} />
          </div>
        </div>
      ); 
  }

}
export default Private;