import { getGlobal } from 'reactn';
import md5 from 'js-md5';

export default class FavoritesController {
	constructor(chatClient) {
		this.chatClient = chatClient;
		this.favorites = getGlobal().favorites;
	}

	returnUserArrayIndex(name) {
		for (const [index, { id }] of this.favorites.entries()) {
			if (id === md5(name)) {
				return index;
			}
		}
		return -1;
	}

	deleteUser(name) {
		const index = this.returnUserArrayIndex(name);
		if (index !== -1) {
			const users = this.favorites.slice();
			users.splice(index, 1);
			this.chatClient.setGlobal({ favorites: users });
		}
	}

	listItemProcess(res) {
		res.forEach((ds, i) => {
			if (i !== 0) {
				ds.split("\x01").forEach((user) => {
					if (user !== '') {
						this.appendUsers(user);
					}
				});
			}
		});
	}

	userInFavoriteList(username) {
		return this.favorites.filter(({ name }) => name === username).length > 0;
	}

	appendUsers(name) {		
		if (!this.userInFavoriteList(name)) {
			const user = { id: md5(name), name };
			const users = [...this.favorites, user];
			this.chatClient.setGlobal({ favorites: users });
			this.favorites = users;
		}
	}
}
