import React, { PureComponent, setGlobal, getGlobal } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import Popup from "reactjs-popup";
import Select from 'react-select'
import { DateTimeSpinner } from 'rc-easyui';
import { format } from 'date-fns';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import 'reactjs-popup/dist/index.css';

class ModeratorModal extends PureComponent {

    constructor(props) {
        super(props);
        this.client = clientStorage.id;
        this.handleOnOpen = this.handleOnOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.privateOpen = this.privateOpen.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.apiUrl = this.global.apiUrl;

        var today = new Date();
        today.setHours(today.getHours() + 1);

        this.state = {
            selectedUser: null,
            selectedType: null,
            selectedRoom: null,
            selectedReason: null,
            invalidUser: false,
            templates:[],
            moderations:[],
            time: format(today, 'yyyy/MM/dd kk:mm')
        }
    }

    handleOpenModal() {
        setGlobal({ moderatorModalOpened: true });
    }

    getTemplates() {
        let fd = new FormData();
        fd.append('getModeratorTemplates', true);
        fd.append('sid', this.global.sid);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ loading: true });
        axios.post(this.apiUrl + '/getModeratorTemplates', fd, { headers })
            .then(res => {
                if (res.data.getModeratorTemplates === 'ok') {
                    let templates = [];
                    console.log(res.data);
                    res.data.templates.map((template) => (
                        templates.push({label: template, value: template})
                    ));
                    this.setState({ templates: templates });
                } 
            })
            return null;
    }

    getModerations(username) {
        if (typeof username !== 'undefined') {
            let fd = new FormData();
            fd.append('getUserModerations', true);
            fd.append('sid', this.global.sid);
            fd.append('username', username);
            // this.setState({ moderations: [] });
            const headers = {
                'Content-Type': 'multipart/form-data'
            };
            this.setState({ loading: true });
            axios.post(this.apiUrl + '/getUserModerations', fd, { headers })
                .then(res => {
                    if (res.data.getUserModerations === 'ok') {
                        console.log(res.data);
                        let moderations = [];
                        for (var key in res.data.history) {
                            moderations.push(res.data.history[key]);
                        }                 
                        moderations.reverse();
                        this.setState({ moderations: moderations, invalidUser: false});
                    } else {
                        this.setState({
                            selectedUser: null,
                            selectedType: null,
                            selectedRoom: null,
                            selectedReason: null,
                            invalidUser: true
                            });
                    } 
                })
        }
    }

    handleCloseModal() {
        var today = new Date();
        this.setState({
                    selectedUser: null,
                    selectedType: null,
                    selectedRoom: null,
                    selectedReason: null,
                    time: format(today, 'yyyy/MM/dd kk:mm')
                    });
        setGlobal({ moderatorModalOpened: false });
    }

    handleOnOpen() {

        if (this.global.moderatorModalOpenedUser !== '' && typeof this.global.moderatorModalOpenedUser !== 'undefined') {
            this.setState({ selectedUser: {value: this.global.moderatorModalOpenedUser, label: this.global.moderatorModalOpenedUser}});
            this.getModerations(this.global.moderatorModalOpenedUser);
        }
        if (this.global.moderatorModalOpenedRoom !== '' && typeof this.global.moderatorModalOpenedRoom !== 'undefined') {
            this.setState({ selectedRoom: {value: this.global.moderatorModalOpenedRoom, label: this.global.moderatorModalOpenedRoom}});
        }

        var today = new Date();
        today.setHours(today.getHours() + 1);
        this.setState({time: format(today, 'yyyy/MM/dd kk:mm')});
        this.getTemplates();
    }

    privateOpen(e, name) {
        this.handleCloseModal();
        this.client.sendMessage("private_join\x00" + name);
    }

    changeUser = (selectedOption) => {
        this.setState({ selectedUser: selectedOption });

        if (selectedOption !== null) {
            this.getModerations(selectedOption.value);
        }
    }

    changeType = (selectedOption) => {
        this.setState({ selectedType: selectedOption });
    }

    changeRoom = (selectedOption) => {
        this.setState({ selectedRoom: selectedOption });
    }

    changeReason = (selectedOption) => {
        this.setState({ selectedReason: selectedOption });
    }

    handleSubmit(e) {
        if (this.state.selectedType.value === 'roomban') {
            this.client.sendMessage("moderator\x00" + this.state.selectedUser.value +"\x00"+this.state.selectedType.value+"\x00"+this.state.selectedRoom.value+"\x00"+this.state.time+"\x00"+this.state.selectedReason.label);
        } else if (this.state.selectedType.value === 'roomsilence') {
            this.client.sendMessage("moderator\x00" + this.state.selectedUser.value +"\x00"+this.state.selectedType.value+"\x00"+this.state.selectedRoom.value+"\x00"+this.state.time+"\x00"+this.state.selectedReason.label);
        } else if (this.state.selectedType.value === 'userban') {
            this.client.sendMessage("moderator\x00" + this.state.selectedUser.value +"\x00"+this.state.selectedType.value+"\x00\x00"+this.state.time+"\x00"+this.state.selectedReason.label);
        } else if (this.state.selectedType.value === 'alert') {
            this.client.sendMessage("moderator\x00" + this.state.selectedUser.value +"\x00"+this.state.selectedType.value+"\x00\x00"+this.state.time+"\x00"+this.state.selectedReason.label);
        }
        this.handleCloseModal();
    }

    withdraval(e, id) {
        let fd = new FormData();
        fd.append('moderationWithdraval', true);
        fd.append('sid', this.global.sid);
        fd.append('id', id);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ loading: true });
        axios.post(this.apiUrl + '/moderationWithdraval', fd, { headers })
            .then(res => {
                if (res.data.moderationWithdraval === 'ok') {
                    this.getModerations(this.state.selectedUser.value);
                } 
            })
    }


    render() {

        let users = getGlobal().users;
        let userList = [];
        users.map((arr, index) => (
            userList.push({label: arr.name, value: arr.name})
        ));

        let rooms = getGlobal().rooms;
        let selectedRoomName;
        let roomList = [];

        for (var key in rooms) {
            for (var item in rooms[key]['children']) {     
                let arr = rooms[key]['children'][item];
                if (arr['id'] === this.global.moderatorModalOpenedRoom) {
                    selectedRoomName = arr['text'];
                }
                roomList.push({label: arr['text'], value: arr['id']})
            }
        }

        const types = [
            { value: 'roomban', label: 'Kitiltás a szobából' },
            { value: 'roomsilence', label: 'Elnémítás a szobában' },
            { value: 'userban', label: 'Felhasználó kitiltása' },
            { value: 'alert', label: 'Figyelmeztetés' }
        ]

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: '34px',
                height: '34px',
                boxShadow: state.isFocused ? null : null,
            }),
        
            valueContainer: (provided, state) => ({
                ...provided,
                height: '34px',
                padding: '0 6px'
            }),
        
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                height: '34px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '34px',
            }),
        };

          const { time } = this.state;
          return (
            <Popup open={this.global.moderatorModalOpened} closeOnDocumentClick={true} onClose={this.handleCloseModal} onOpen={this.handleOnOpen} className="ModeratorModalPopup">
                {close => (
                    <div className="modal ModeratorModal">
                        <button className="close" onClick={close} />

                            <div style={{ position: 'relative', height: '100%' }}>
                                <div className="header">Moderálás</div>
                                <Scrollbar noScrollX={true} removeTracksWhenNotUsed={false}>
                                    <div className="content">
                                        <div className="field">
                                            <label>Felhasználó:</label>
                                        
                                        
                                        {this.global.moderatorModalOpenedUser !== "" &&
                                        <CreatableSelect onChange={this.changeUser} defaultValue={{ label: this.global.moderatorModalOpenedUser, value: this.global.moderatorModalOpenedUser }} options={userList} placeholder="Felhasználó kiválasztása" isClearable="true" className="modal-select" styles={customStyles} />
                                        }

                                        {this.global.moderatorModalOpenedUser === "" &&
                                        <CreatableSelect onChange={this.changeUser} options={userList} placeholder="Felhasználó kiválasztása" isClearable="true" className="modal-select" styles={customStyles} />
                                        }
                                        </div>

                                        {this.state.selectedUser !== null &&
                                            <div className="field">
                                                <label>Típus:</label>
                                                <Select onChange={this.changeType} options={types} placeholder="Típus kiválasztása" isClearable="true" className="modal-select" styles={customStyles} />
                                            </div>
                                        }

                                        {this.global.moderatorModalOpenedRoom === "" && this.state.selectedUser !== null && this.state.selectedType !== null && this.state.selectedType.value !== 'userban' && this.state.selectedType.value !== 'alert' &&
                                            <div className="field">
                                               <label>Szoba:</label>
                                               <Select onChange={this.changeRoom} options={roomList} placeholder="Szoba kiválasztása" isClearable="true" className="modal-select" styles={customStyles} />
                                            </div>
                                        } 

                                        {this.global.moderatorModalOpenedRoom !== "" && this.state.selectedUser !== null && this.state.selectedType !== null && this.state.selectedType.value !== 'userban' && this.state.selectedType.value !== 'alert' &&
                                            <div className="field">
                                                <label>Szoba:</label>
                                                <Select onChange={this.changeRoom} defaultValue={{ label: selectedRoomName, value: this.global.moderatorModalOpenedRoom }} options={roomList} placeholder="Szoba kiválasztása" isClearable="true" className="modal-select" styles={customStyles} />
                                            </div>
                                        }

                                       {this.state.selectedUser !== null && this.state.selectedType !== null  &&
                                            <div className="field">
                                               <label>Indok:</label>
                                               <CreatableSelect onChange={this.changeReason} options={this.state.templates} placeholder="Indok megadása" isClearable="true" className="modal-select" styles={customStyles} />
                                            </div>
                                        }

                                       {this.state.selectedUser !== null && this.state.selectedType !== null && this.state.selectedReason !== null && this.state.selectedType.value !== 'alert' &&

                                        <div className="field">
                                            <label>Lejárati idő:</label>
                                            <DateTimeSpinner
                                                inputId="t1"
                                                value={time}
                                                format="yyyy/MM/dd HH:mm"
                                                selections={[[0, 4], [5, 7], [5, 7], [8, 10], [11, 13], [14, 16]]}
                                                onChange={(value) => this.setState({ time: value })}
                                            />
                                            </div>
                                        }
 
                                        {this.state.selectedUser !== null && this.state.selectedType !== null && this.state.selectedReason !== null &&

                                        <p className="submit field">
                                             <label></label>
                                            <button type="submit" className="moderation-button"  onClick={(e) => this.handleSubmit(e)}>Mehet</button>
                                        </p>
                                         }

                                        {this.state.selectedUser !== null &&
                                            <div className='history'>
                                            <h3>{this.state.selectedUser.value} moderálási előzményei:</h3>

                                                {this.state.moderations.map((arr, id) => {

                                                    if (arr.type === 'alert') {
                                                        return <p><span className='created'>({arr.created})</span> <span className='message'>{arr.message}</span> <span className='reason'>"{arr.reason}"</span></p>
                                                    } else {

                                                        if (arr.withdrawal === false && arr.deleted === null) {
                                                            return <p><span className='message'>{arr.message}</span> <span className='reason'>"{arr.reason}"</span> <span className='created'>A tiltás ideje: {arr.created}</span> <span className='expiration'>A tiltás lejárati ideje: {arr.expirationDate}</span> <span className="withdraval"><button  onClick={(e) => this.withdraval(e,arr.id)}>Visszavonás</button></span></p>
                                                        } else {
                                                            if (arr.deleted !== null) {
                                                                return <p><span className='message'>{arr.message}</span> <span className='reason'>"{arr.reason}"</span> <span className='created'>A tiltás ideje: {arr.created}</span> <span className='expiration'>A tiltás lejárati ideje: {arr.expirationDate}</span> <span className='expiration'>A tiltást visszavonta: {arr.deleter}, ekkor: {arr.deleted}</span></p>
                                                            } else {
                                                                return <p><span className='message'>{arr.message}</span> <span className='reason'>"{arr.reason}"</span> <span className='created'>A tiltás ideje: {arr.created}</span> <span className='expiration'>A tiltás lejárati ideje: {arr.expirationDate}</span></p>
                                                            }
                                                        }
                                                    }
                                               
                                                })} 

                                                {this.state.moderations.length === 0 &&                                                    
                                                    <div className="no-moderations">{this.state.selectedUser.value} még nem lett moderálva.</div>
                                                }
                                            </div>
                                        }

                                        { this.state.invalidUser === true &&
                                            <div className="alert">Nem regisztrált felhasználó!</div>
                                        }

                                    </div>
                                </Scrollbar>
                            </div>

                    </div>
                )}
            </Popup>
        );

    }
}


export default ModeratorModal;