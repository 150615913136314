import { getGlobal } from 'reactn';

export default class settingsController {
	constructor(chatClient) {
		this.chatClient = chatClient;
	}

	saveColor(color) {
		this.chatClient.setGlobal({
			currentColor: color
		});
	}

	//statuscodes: beeping|logolas|be/kilepesek|highlight|reconnect
	//adat ki: settings|1|0|0|0|0|automsg|status|highlight szavak|reconnect rooms
	//adat be: settings 1110010 kjlklk56 k undefined
	//0: beeping
	//1: joins
	//2: highlightUsername
	//5: status

	updateProfileImage(image) {
		let user = getGlobal().user;
		user['profileImage'] = image; 
		this.chatClient.setGlobal({
			user: user
		});
	}


	setSettings(res) {
		var user = {};
		var codes = res[1];
		var modernView = true;
		var coloredMessages = false;
		var fontSize = 0;
		var urlPreview = true;
		var smileys = true;
		var roomListTree = false;


		if (localStorage.getItem('modernView') === 'false') {
			modernView = false;
		}
		if (localStorage.getItem('coloredMessages') === 'false') {
			coloredMessages = false;
		}

		if (localStorage.getItem('smileys') === 'false') {
			smileys = false;
		}

		if (localStorage.getItem('urlPreview') === 'false') {
			urlPreview = false;
		}

		if (localStorage.getItem('roomListTree') === 'true') {
			roomListTree = true;
		}

		if (localStorage.getItem('fontSize') !== "" && localStorage.getItem('fontSize') !== null) {
			if (localStorage.getItem('fontSize') !== '0' ) {
				fontSize = localStorage.getItem('fontSize');
				document.body.style.fontSize = `${fontSize}px`;
			}
		}

		user = {
			autoMessage: res[2],
			highlight: res[3],
			statuscodes: res[1],
			beeping: !!parseInt(codes[0]),
			joins: !!parseInt(codes[1]),
			status: parseInt(codes[5]),
			highlightUsername: !!parseInt(codes[2]),
			modernView: modernView,
			coloredMessages: coloredMessages,
			fontSize:fontSize,
			userID: res[4],
			profileImage: res[5],
			vipColor: res[6],
			urlPreview: urlPreview,
			smileys: smileys,
			roomListTree: roomListTree
		};
		this.chatClient.setGlobal({
			user: user
		});

	}

}