import React, { setGlobal, getGlobal } from "reactn";
import { Tabs, TabPanel } from "rc-easyui";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Circle } from "react-preloaders";
import { Helmet } from "react-helmet";
import md5 from "js-md5";
import shortid from "shortid";
import ab2str from "arraybuffer-to-string";
import UIfx from "uifx";
import beepAudio from "./assets/beep.wav";
import mentionAudio from "./assets/mention.wav";

import "react-notifications/lib/notifications.css";

// tchat osztalyok
import statusController from "./classes/statusController";
import roomListController from "./classes/roomListController";
import userListController from "./classes/userListController";
import settingsController from "./classes/settingsController";
import roomController from "./classes/roomController";
import privateController from "./classes/privateController";
import tabController from "./classes/tabController";
import userStatesController from "./classes/userStatesController";
import notificationsController from "./classes/notificationsController";
import ignoresController from "./classes/ignoresController";
import favoritesController from "./classes/favoritesController";
import clientStorage from "./classes/clientStorage";

//tchat komponensek
import Login from "./components/Login";
import RoomList from "./components/RoomList";
import RoomListBoxes from "./components/RoomListBoxes";
import UserList from "./components/UserList";
import UserListWithProfileImage from "./components/UserListWithProfileImage";

import Settings from "./components/Settings";
import Room from "./components/Room";
import Private from "./components/Private";
import Notifications from "./components/Notifications";
// import Statusbar from "./components/Statusbar";
import ProfileModal from "./components/ProfileModal";
import ModeratorModal from "./components/ModeratorModal";

import { Capacitor } from '@capacitor/core';
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Device } from '@capacitor/device';

const setStatusbarStyle = async (mode) => {
  const info = await Device.getInfo();
  if (info.platform !== 'web') {
    StatusBar.setOverlaysWebView({ overlay: true });
    StatusBar.setStyle({style: Style.Dark});
  }
};

const setSafeAreas = async () => {
  const info = await Device.getInfo();
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      const root = document.documentElement;     
      if (info.platform !== 'android') {
        root.style.setProperty('--app-safe-area-padding-top', insets.top+'px');
        root.style.setProperty('--app-safe-area-padding-right', insets.right+'px');
        root.style.setProperty('--app-safe-area-padding-bottom', insets.bottom+'px');
        root.style.setProperty('--app-safe-area-padding-left', insets.left+'px');
        root.style.setProperty('--app-safe-area-height', insets.top+'px');
      } else {
        root.style.setProperty('--app-safe-area-padding-top', insets.top+'px');
        root.style.setProperty('--app-safe-area-padding-right', insets.right+'px');
        root.style.setProperty('--app-safe-area-padding-bottom', insets.bottom+'px');
        root.style.setProperty('--app-safe-area-padding-left', insets.left+'px');
        root.style.setProperty('--app-safe-area-height', (insets.top + insets.bottom)+'px');
      }
    });  
};

const beep = new UIfx(beepAudio, { volume: 0.5, throttleMs: 50 });
const mention = new UIfx(mentionAudio, { volume: 0.5, throttleMs: 50 });
const MySwal = withReactContent(Swal);

class App extends React.Component {
  constructor(props) {
    super(props);
    clientStorage.id = this;
    this.addMessage = this.addMessage.bind(this);
    this.addContent = this.addContent.bind(this);
    this.sendAlert = this.sendAlert.bind(this);
    this.hideLoader = props.hideLoader;
    this.showLader = props.showLoader;

    this.reconnectDisabled = false;
    this.reconnectTimer = null;
    this.connected = false;
    this.statusTimer = {};

    /** Osztalyok **/
    this.statusController = new statusController(this);
    this.roomList = new RoomList(this);
    this.roomListController = new roomListController(this);
    this.userListController = new userListController(this);
    this.settingsController = new settingsController(this);
    this.roomController = new roomController(this);
    this.privateController = new privateController(this);
    this.tabController = new tabController(this);
    this.userStatesController = new userStatesController(this);
    this.notificationsController = new notificationsController(this);
    this.ignoresController = new ignoresController(this);
    this.favoritesController = new favoritesController(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      data: [
        {
          id: "login",
          title: "Belépés",
          content: "",
          module: "login",
          icon: "icon-login",
          closed: false,
        },
      ],
      tabPosition: "left",
      selectedIndex: 0,
      autoSelectTab:false,
      loading: false,
    };

    this.alert = { title: "", text: "", type: "" };
    if (localStorage.getItem("showMobileTabs") === "disabled") {
      this.setGlobal({
        showMobileTabs: false,
      });
    }

    if (localStorage.getItem("showRoomUserlist") === "disabled") {
      this.setGlobal({
        showRoomUserlist: false,
      });
    }

    if (localStorage.getItem("username") !== "") {
      this.setGlobal({
        username: localStorage.getItem("username"),
      });
    }
  }  

  componentDidMount() {
    window.addEventListener('keyboardDidShow', (e) => {
      window.dispatchEvent(new Event('resize')); //temporary scroll-assist fix
    }, false);

    setStatusbarStyle(getGlobal().themeMode);
    if (
      localStorage.getItem("sid") !== "" &&
      localStorage.getItem("sid") !== null
    ) {
      this.setState({
        loading: true,
      });
      this.setGlobal({
        sid: localStorage.getItem("sid"),
      });
      setTimeout(() => this.connect("", "", "session"), 200);
    } else {
      this.setState({
        loading: false, //fixme
      });
    }
    if (
      localStorage.getItem("username") !== "" &&
      localStorage.getItem("username") !== null
    ) {
      this.setGlobal({
        username: localStorage.getItem("username"),
      });
    }
    if (Capacitor.getPlatform() !== 'web') {
      this.getToken();
    }    
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", setSafeAreas());
    setSafeAreas();
  }
  
  componentDidUpdate(prevProps, prevState) {
    this.tabController.changeOrder();
    if (prevState.autoSelectTab === false && this.state.autoSelectTab === true) {
      this.tabController.selectModuleTab('roomList');
    }
  }

  getToken() {
    PushNotifications.requestPermissions().then(async (permission) => {
        if (permission.receive === "granted") {
            // Register with Apple / Google to receive push via APNS/FCM
            if (Capacitor.getPlatform() === 'ios') {
                await PushNotifications.register();
                PushNotifications.addListener('registration', (token: Token) => {
                    FCM.getToken().then((result) => {
                        this.setToken(result.token);
                    }).catch((err) => console.log('i am Error', err));
                })
            } else if (Capacitor.getPlatform() === 'android') {
                await PushNotifications.register()
                PushNotifications.addListener('registration', async ({ value }) => {
                    this.setToken(value);
                });
            }
        }
    });
  }

  setToken(token) {
    if (this.connected) {
      this.sendMessage("fcm_token\x00"+token);
    }
    this.setGlobal({FCMToken: token});
  }


  showLoading() {
    this.setState({
      loading: true,
    });
  }

  hideLoading() {
    this.setState({
      loading: false,
    });
  }
  
  
  connect(username, password, type) {
    if (this.connected === false) {
      var that = this;

      this.socket = new WebSocket(
        "wss://chat.teamchat.hu/chatserver/",
        "tchat"
      );
      this.socket.binaryType = "arraybuffer";
      this.connected = false;

      this.socket.onerror = function (error) {
        that.setState({
          loading: false,
        });

        if (getGlobal().reconnect === true) {
          that.disconnect(false);
        } else {
          // NotificationManager.info(
          //   "Nem sikerült csatlakozni a szerverhez!",
          //   "Figyelem!",
          //   2000
          // );
        }
      };

      this.socket.onopen = () => {
        this.sendMessage("webclient");

        if (type === "login") {
          this.sendMessage(
            "login\x00" +
              username +
              "\x00" +
              md5(password) +
              "\x00flat\x00" +
              that.global.version
          );
        } else {
          this.sendMessage(
            "session\x00" +
              localStorage.getItem("sid") +
              "\x00flat\x00" +
              that.global.version +"\x00"+Capacitor.getPlatform()
          );
        }
        this.connected = true;
      };

      this.socket.onclose = () => {
        if (that.connected === true) {
          if (that.reconnectDisabled === true) {
            that.disconnect(true);
          } else {
            that.disconnect(false);
          }
        }
        that.statusController.stop();
      };
 
      this.socket.onmessage = function (event) {
        var line = ab2str(event.data, "utf8");
        if (line !== "") {
          var res = line.split("\x00");

          //console.log('haha');
          console.log(
            "adat be: " +
              res[0] +
              " " +
              res[1] +
              " " +
              res[2] +
              " " +
              res[3] +
              " " +
              res[4] +
              " " +
              res[5] +
              " " +
              res[6] +
              " " +
              res[7]
          );

          switch (res[0]) {
            case "login":
              if (res[1] === "ok") {
                if (username !== "") {
                  that.setGlobal({
                    username: username,
                    users: [],
                    serverTime: res[3]
                  });
                } else {
                  that.setGlobal({
                    serverTime: res[3]
                  });
                }

                if (getGlobal().FCMToken !== null) {
                  that.sendMessage("fcm_token\x00"+getGlobal().FCMToken);
                }

                that.statusController.start();
                that.reconnect = false;
               // that.notificationsController.add("Beléptél a chatre.", false);
                that.tabController.addTab(
                  "notifications",
                  "Értesítések",
                  "notifications",
                  "icon-notifications"
                );
              } else {
                that.reconnect = false;
                that.reconnectDisabled = true;
                that.sendAlert(
                  "Hiba!",
                  "Rossz nevet vagy jelszót adtál meg!",
                  "warning"
                );
              }
              break;
            case "sid":
              localStorage.setItem("sid", res[1]);
              that.setGlobal({ sid: res[1] });
              break;

            case "badsid":
              localStorage.removeItem("sid");
              that.setGlobal({ sid: 0 });
              that.reconnectDisabled = true;
              NotificationManager.info(
                "Lejárt munkamenet, lépj be újra!",
                "Hiba!",
                20000
              );
              break;
            case "status":
              if (res[1] === "logined") {

                let autoSelectTab = false;
                if (getGlobal().reconnect === true) {
                  that.roomController.reconnectRooms();
                  that.privateController.reconnectPrivates();
                } else {
                  autoSelectTab = true;                  
                }

                that.reconnectDisabled = false;
                that.setGlobal({
                  reconnect: false,
                  freezed: false,
                  logined: true,
                });
                that.setState({
                  loading: false,
                  autoSelectTab: autoSelectTab
                });
              }
              break;
            case "adduser":
              that.userListController.appendUsers(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5],
                "simple",
                res[6]
              );
              break;

            case "deluser":
              that.userListController.deleteUser(res[1]);
              break;

            case "users":
              that.userListController.listItemProcess(res);
              break;

            case "users_addtab":
              if (that.tabController.exists("userList") === false) {
                that.tabController.addTab(
                  "userList",
                  "Felhasználók",
                  "userList",
                  "icon-users"
                );
              }
              break;

            case "reconnect":
              that.reconnectDisabled = true;
              break;

            case "username":
              that.setGlobal({ username: res[1] });
              break;

            case "moderator":
              that.setGlobal({ moderator: true });
              break;

            case "getstatus":
              that.setGlobal({ onlineUsers: res[1], serverTime: res[3] });
              that.roomListController.updateCounters(res[2]);
              break;

            case "color":
              that.settingsController.saveColor(res[1]);
              break;

            case "alert":
              NotificationManager.info(res[2], res[1], 10000);
              if (res[3] === "1") {
                that.notificationsController.add(res[1] + ": " + res[2], false);
              } else {
                that.notificationsController.add(res[1] + ": " + res[2], true);
              }
              break;

            case "modal":
              that.sendAlert(res[1], res[2], res[3]);
              that.notificationsController.add(res[1] + ": " + res[2], false);
              break;

            case "rooms":
              that.roomListController.listItemProcess(res);
              that.tabController.addTab(
                "roomList",
                "Szobák",
                "roomList",
                "icon-rooms"
              );
              // that.tabController.selectModuleTab('roomList');

              break;

            case "settings":
              that.settingsController.setSettings(res);
              that.tabController.addTab(
                "settings",
                "Beállítások",
                "settings",
                "icon-settings"
              );
              break;

            case "profileImage":
              that.settingsController.updateProfileImage(res[1]);
              break;

            case "ignores":
              that.ignoresController.listItemProcess(res);
              break;

            case "ignore_del":
              that.ignoresController.deleteUser(res[1]);
              break;

            case "ignore_add":
              that.ignoresController.appendUsers(res[1]);
              break;

            case "favorites":
              that.favoritesController.listItemProcess(res);
              break;

            case "favorite_del":
              that.favoritesController.deleteUser(res[1]);
              break;

            case "favorite_add":
              that.favoritesController.appendUsers(res[1]);
              break;

            case "user_counter":
              that.roomListController.updateRoomUserCounter(res[1], res[2]);
              break;

            case "userState":
              that.userStatesController.changeState(res[1], res[2]);
              break;

            case "change_profileimage":
              console.log("change_profileimage");
              that.userStatesController.changeProfileImage(res[1], res[2]);
              break;

            case "room_add":
              that.roomController.roomAdd(res);
              // if (that.tabController.existsRoom(res[1]) === false) {
              // 	setTimeout(() => that.tabController.selectRoomTab(res[1]));
              // }
              break;

            case "room_settings":
              that.roomController.roomSettings(res);
              break;

            case "private_add":
              that.privateController.privateAdd(res);
              break;

            case "private_disturb":
              NotificationManager.info(
                res[1] +
                  " ne zavarj módban van ezért nem tudsz vele csevegést kezdeményezni!",
                "Figyelem!",
                6000
              );
              break;

            case "room_del":
              that.tabController.delRoomTab("room", res[1]);
              //that.roomController.updateRoomState(res[1], 'closed');
              break;

            case "private_del":
              that.tabController.delPrivateTab("private", res[1]);
              break;

            case "roommsg":
              that.roomController.appendMessage(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5],
                res[6],
                res[7]
              );
              break;

            case "privatemsg":
              that.privateController.appendMessage(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5],
                res[6],
                res[7]
              );
              break;

            case "private_offline":
              that.privateController.appendServerMessage(
                res[1],
                res[2] + " kilépett a chatről.",
                shortid.generate(),
                getGlobal().userPartColor
              );
              break;

            case "private_not_exists":
              that.privateController.privateNotExists(res[1], res[2]);
              break;

            case "room_servermsg":
              that.roomController.appendServerMessage(res[1], res[2], res[3]);
              break;

            case "room_addtab":
              that.roomController.delFreezedRoom(res[1]);
              that.roomController.roomAddTab(res[1]);
              break;

            case "room_users":
              that.roomController.processUsers(res[1], res);
              break;

            case "private_users":
              that.privateController.processUsers(res[1], res[2]);
              break;

            case "room_topic":
              that.roomController.setTopic(res[1], res[2], res[3]);
              that.roomController.appendServerMessage(
                res[1],
                "A szoba témája: " + res[2],
                shortid.generate()
              );
              break;

            case "room_join":
              that.roomController.appendUser(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5],
                res[6],
                res[7]
              );
              break;

            case "room_part":
              that.roomController.removeUser(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5]
              );
              break;

            case "private_join":
              that.privateController.appendUser(res[1], res[2], res[3], res[4]);
              break;
              
            case "private_part":
              that.privateController.removeUser(
                res[1],
                res[2],
                res[3],
                res[4],
                res[5]
              );
              break;

              case "private_notify":
                that.privateController.notify(res[1]);
                break;

            default:
          }
        }
      };
    } else {
      this.sendAlert("Hiba!", "Már be vagy jelentkezve!", "warning");
    }
  }

  disconnect(type) {
   // this.notificationsController.add("Megszakadt a kapcsolat!", true);
    this.setState({
      loading: false,
      autoSelectTab:false
    });

    if (type === true) {
      if (this.connected === true) {
        this.socket.close();
        this.connected = false;
      } else {
        this.connected = false;
      }

      this.roomController.clearRooms();
      this.privateController.clearPrivates();

      this.tabController.delAllTab();

      setGlobal({
        currentColor: "FFFFFF",
        user: {
          autoMessage: null,
          highlight: null,
          status: null,
          beeping: true,
          joins: true,
          highlightUsername: true,
          statuscodes: null,
        },
        moderator: false,
        rooms: [],
        topics: [],
        users: [],
        ignores: [],
        notifications: [],
        logined: false,
        username: localStorage.getItem("username"),
        sid: getGlobal().sid,
      });
    } else {
      let reconnectSeconds = Math.floor(Math.random() * 1) + 1;

      this.setGlobal({ reconnect: true });
      this.setState({
        loading: true,
      });

      this.reconnectTimer = setTimeout(
        () => this.reconnectAfter(),
        reconnectSeconds * 1000
      );

      if (this.connected === true) {
        this.socket.close();
        this.connected = false;
      } else {
        this.connected = false;
      }

      this.roomController.clearRooms();
      this.privateController.clearPrivates();
      this.roomController.freezeRooms();
      this.privateController.freezePrivates();

      setGlobal({
        moderator: false,
        rooms: [],
        topics: [],
        users: [],
        ignores: [],
        freezed: true,
        logined: false,
      });
    }
  }

  reconnectAfter() {
    if (this.connected === false) {
      this.connect("", "", "session");
    }
  }

  playSoundNotification() {
    if (getGlobal().user.beeping === true) {
      beep.play();
    }
  }

  playMentionNotification() {
    if (getGlobal().user.beeping === true) {
      mention.play();
    }
  }

  sendAlert(title, text, type) {
     this.text = text.replace(/\n/g, "<br />");
     MySwal.fire({
      title: title,
      html: text,
      icon: type
    });
  }

  sendNotification(title,text,time=5000) {
    NotificationManager.info(
      text,
      title,
      time
    );
  }

  sendMessage(msg) {
    //console.log("adat ki: " + msg);
    this.socket.send(msg + "\n");
  }

  addMessage(message) {
    let messages = this.messages.room;
    messages.push({
      mess: message,
    });
    this.setState({
      messages: messages,
    });
  }

  addContent(module, content) {
    let data = this.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {
        data[key]["content"] = content;
      }
    }
    this.setState({
      data: data,
    });
  }

  handleClickOutside(event) {
    if (this.tabsRef && !this.tabsRef.tabsRef.contains(event.target)) {
      this.hideMobileTabs();
    }
  }

  showMobileTabs(initial) {
    if (this.global.showMobileTabs === true) {
      localStorage.setItem("showMobileTabs", "disabled");
      this.setGlobal({
        showMobileTabs: false,
      });
    } else {
      localStorage.setItem("showMobileTabs", "enabled");
      this.setGlobal({
        showMobileTabs: true,
      });
    }
  }

  hideMobileTabs() {
    if (document.body.clientWidth <= 768) {
      localStorage.setItem("showMobileTabs", "enabled");
      this.setGlobal({
        showMobileTabs: true,
      });
    }
  }

  disableReconnect() {
    this.setGlobal({ reconnect: false });
    this.setState({
      loading: false,
      selectedIndex: 0,
    });
    clearTimeout(this.reconnectTimer);
  }

  closeRoom(roomID) {
    this.sendMessage("room_part\x00" + roomID);
  }

  closePrivate(privateID) {
    this.sendMessage("private_part\x00" + privateID);
  }

  // ********** render() ********** //
  render() {
    let tabsClasses;
    if (this.global.showMobileTabs === true) {
      tabsClasses = "mainTabs mobileTabs disable-scrollbars";
    } else {
      tabsClasses = "mainTabs fullTabs disable-scrollbars";
    }

    let title;

    if (this.global.username === "" || this.global.username === null) {
      title = this.global.appName;
    } else {
      title = `${this.global.username} - ${this.global.appName}`;
    }

    if (this.connected) {
      tabsClasses += " logined";
    } else {
      tabsClasses += " loggedout";
    }

    return (
      <div className="app-container">
        <Helmet>
          <title>{title}</title>
        </Helmet>
       
        <div id="chatframe" className="App">    

        { this.connected === true && (

          <div id="mainmenu">
            <div className="container">
              <div className="mainmenu">
                {this.global.showMobileTabs === true && this.connected === true && (
                  <button
                    id="showMobileTabs"
                    className="mobileTabsShow"
                    onClick={() => this.showMobileTabs()}
                  ></button>
                )}

                {this.global.showMobileTabs === false && this.connected === true && (
                  <button
                    id="showMobileTabs"
                    className="mobileTabsHide"
                    onClick={() => this.showMobileTabs()}
                  ></button>
                )}

              <div className="mainTitle">
                <span className={this.global.selectedTabIcon}></span>{this.global.selectedTabTitle}
              </div>

              </div>
 

              <div className="menubar">
                {this.global.selectedTabModule === 'private' &&
                  <div className="close">
                    <input type="button" className="privateClose" onClick={() => this.closePrivate(this.global.selectedTab)} value="" />
                  </div>
                }

                {this.global.selectedTabModule === 'room' &&
                  <div className="close">
                    <input type="button" className="roomClose" onClick={() => this.closeRoom(this.global.selectedTab)} value="" />
                  </div>
                }
              </div>
            </div> 
          </div>              
          )}
          <Tabs
            ref={(el) => (this.tabsRef = el)}
            className={tabsClasses}
            scrollable={true}
            plain={true}
            tabPosition={this.state.tabPosition}
            selectedIndex={this.state.selectedIndex} 
            onTabSelect={(panel) => {
              const selectedIndex = this.tabsRef.getPanelIndex(panel);
              this.tabController.noColorByIndex(selectedIndex);
              this.privateController.checkSeen(selectedIndex);
              this.setState({ selectedIndex });
              this.hideMobileTabs();
              setGlobal({
                selectedTab: this.state.data[selectedIndex].content,
                selectedTabModule: this.state.data[selectedIndex].module,
                selectedTabTitle: this.state.data[selectedIndex].title,
                selectedTabIcon: this.state.data[selectedIndex].icon,
              });
            }}  
          >


            {this.state.data.map((tab, index) => {
              if (tab.module === "login") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    <Login connected={this.connected} />{" "}
                  </TabPanel>
                );
              } else if (tab.module === "roomList") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    {this.global.user.roomListTree === true &&
                    <RoomList />
                    }
                    {this.global.user.roomListTree === false &&
                    <RoomListBoxes />
                    }

                  </TabPanel>
                );
              } else if (tab.module === "settings") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    <Settings />
                  </TabPanel>
                );
              } else if (tab.module === "userList") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    {this.global.user.modernView === true && (
                      <UserListWithProfileImage />
                    )}

                    {this.global.user.modernView === false && (
                    <UserList />
                    )}
                  </TabPanel>
                );
               } else if (tab.module === "private") {

                //console.log('private add: '+tab.title+'  id:'+tab.content);
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    <Private privateID={tab.content} privateName={tab.title} />{" "}
                  </TabPanel>
                );

              } else if (tab.module === "room") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    <Room roomID={tab.content} />
                  </TabPanel>
                );
                  
              } else if (tab.module === "notifications") {
                return (
                  <TabPanel
                    key={tab.id}
                    title={tab.title}
                    className={index}
                    closed={tab.closed}
                    iconCls={tab.icon}
                  >
                    <Notifications selectedIndex={this.state.selectedIndex} />{" "}
                  </TabPanel>
                );
              } else {
                return null;
              }
            })}

          </Tabs>

          {/* {this.connected === true && (
            <div className={tabsClasses}>
              <Statusbar />
            </div>
          )} */}



          {this.global.reconnect === true && (
            <div id="reconnectDisable">
              <button onClick={() => this.disableReconnect()}>
                Újrakapcsolódás megszakítása
              </button>
            </div>
          )}

          <ProfileModal />
          <ModeratorModal />
        </div>

        <NotificationContainer />
        {this.state.loading === true && (
          <Circle
            color={"#ffffff"}
            background="rgba(0,0,0,0.7)"
            animation="fade"
          />
        )}
      </div>
    );
  }
}
export default App;
