export default class statusController {
	constructor(chatClient) {
		this.chatClient = chatClient;
	}

	start() {
		var that = this;
		this.chatClient.statusTimer = setInterval(function () {
			that.send();
		}, 20000);
	}

	send() {
		this.chatClient.sendMessage('getstatus');
	}

	stop() {
		clearInterval(this.chatClient.statusTimer);
	}

}