import { getGlobal } from 'reactn';

export default class roomListController {
	constructor(chatClient) {
		this.chatClient = chatClient;
	}

	checkRoomParentIsExists(treeID) {
		let data = getGlobal().rooms.slice();
		const element = data.find((value) => {
		  return value.id === treeID;
		})
		return typeof element === "object" ? true : false;
	}

	getRoomItemByID(roomid) {
		let rooms = getGlobal().rooms.slice();
		for (var key in rooms) {
			var roomData = rooms[key];
			for (var rdKey in roomData) {
				var roomDataItem = roomData[rdKey];
				for (var cKey in roomDataItem) {
					var roomItem = roomDataItem[cKey];
					if (roomItem['id'] === roomid) {
						return roomItem;
					}
				}
			}
		}
	}

	returnRoomParentIndex(treeID) {
		let data = getGlobal().rooms.slice();
		return data.findIndex(function (element) { 
			return element.id === treeID ? true : false;
		}); 
	}

	listItemProcess(res) {
		var that = this;
		var i = 0;
		res.forEach(function (ds) {
			if (i !== 0 && i !== 1) {
				var item = ds.split("\x01");
				if (item[0] !== '' && item[1] !== '') {
					that.appendRooms(item[0], item[1], item[2], item[3], item[4], item[5], item[6], item[7], item[8]);					
				}
			}
			i++;
		});
	}

	appendRooms(name, roomid, color, usercount, key, icon, parent, treeID, background) {
		var room = {};
		let rooms = getGlobal().rooms.slice();
		if (!this.checkRoomParentIsExists(treeID) && parent !== '') {
			room = {};
			room = {
				id: treeID,
				cls: treeID,
				text: parent,
				color: color,
				children: []
			};
			rooms.push(room);
		}

		this.chatClient.setGlobal({
			rooms: rooms
		})
		var parentIndex = this.returnRoomParentIndex(treeID);
		room = {};
		room = {
			id: roomid,
			state: "opened",
			text: name,
			iconCls: icon,
			cls: roomid,
			color: color,
			key: key,
			parent: parent,
			usercount: usercount,
			currentState: 'closed',
			topic: '',
			background: background
		};

		rooms[parentIndex].children.push(room);
		this.chatClient.setGlobal({
			rooms: rooms
		})

	}

	updateRoomUserCounter(roomid, num) {
		let rooms = getGlobal().rooms.slice();
		this.chatClient.setGlobal({
			rooms: []
		});
		for (var key in rooms) {
			var roomData = rooms[key];
			for (var rdKey in roomData) {
				var roomDataItem = roomData[rdKey];
				for (var cKey in roomDataItem) {
					var roomItem = roomDataItem[cKey];
					if (roomItem['id'] === roomid) {
						roomItem['usercount'] = parseInt(num);
						rooms[key].children[cKey] = roomItem;
					}
				}
			}
		}
		this.chatClient.setGlobal({
			rooms: rooms
		});
	}


	updateCounters(jsonData) {
		let rooms = getGlobal().rooms.slice();
		this.chatClient.setGlobal({
			rooms: []
		});

		var obj = JSON.parse(jsonData);
		for (var key in obj) {			
			for (var rkey in rooms) {
				var roomData = rooms[rkey];
				for (var rdKey in roomData) {
					var roomDataItem = roomData[rdKey];
					for (var cKey in roomDataItem) {
						var roomItem = roomDataItem[cKey];
						if (roomItem['id'] === obj[key]['roomID']) {
							roomItem['usercount'] = parseInt(obj[key]['userCount']);
							rooms[rkey].children[cKey] = roomItem;
						}
					}
				}
			}
		}

		this.chatClient.setGlobal({
			rooms: rooms
		});
	}

}