import React, { PureComponent, setGlobal } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage';
import Popup from "reactjs-popup";
import axios from 'axios';

import 'yet-another-react-lightbox/styles.css';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Circle } from 'react-preloaders';
import noimage from '../images/noimage.svg';
import error from '../images/error.svg';
import 'reactjs-popup/dist/index.css';

import LightboxWrapper from './LightboxWrapper';

class ProfileModal extends PureComponent {
    constructor(props) {
        super(props);
        this.client = clientStorage.id;
        this.handleOnOpen = this.handleOnOpen.bind(this);
        this.privateOpen = this.privateOpen.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleLightboxStateChange = this.handleLightboxStateChange.bind(this);
        this.apiUrl = this.global.apiUrl;

        this.state = {
            loading: true,
            profileImage: null,
            userData: { images: [] },
            vipColor: null,
            userIcon: null,
            notLoaded: false,
            closeOnDocumentClick: true,
            closeOnEscape: true
        }
    }

    handleOpenModal() {
        setGlobal({ profileModalOpened: true });
    }

    handleCloseModal() {
        setGlobal({ profileModalOpened: false });
    }

    handleOnOpen() {
        this.getUserData();
    }

    privateOpen(e, name) {
        this.handleCloseModal();
        this.client.sendMessage("private_join\x00" + name);
    }

    handleOpenModeratorModal() {
        this.handleCloseModal();
        setGlobal({ 
            moderatorModalOpened: true,
            moderatorModalOpenedUser: this.global.profileModalOpenedUser,
        });
    }

    handleLightboxStateChange(isOpen) {
        this.setState({ closeOnDocumentClick: !isOpen });
    }

    getUserData() {
        let fd = new FormData();
        fd.append('getProfile', true);
        fd.append('sid', this.global.sid);
        fd.append('username', this.global.profileModalOpenedUser);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ loading: true });
        axios.post(this.apiUrl + '/getProfile', fd, { headers })
            .then(res => {
                if (res.data.getProfile === 'ok') {
                    let userData = JSON.parse(res.data.userData);
                    let vipColor = userData.vipColor !== '' ? { background: "#" + userData.vipColor } : null;
                    let userIcon = userData.vipColor !== '' ? <span className="userIcon icon-star" title="Kiemelt támogató"></span> : null;

                    this.setState({
                        loading: false,
                        notLoaded: false,
                        userData: userData,
                        profileImage: userData.profileImage.thumbnail,
                        vipColor: vipColor,
                        userIcon: userIcon
                    });
                } else {
                    this.setState({ loading: false, notLoaded: true });
                }
            });
    }

    render() {
        const { profileModalOpened } = this.global;
        const { loading, notLoaded, profileImage, vipColor, userIcon, userData} = this.state;

        return (
            <Popup open={profileModalOpened} closeOnDocumentClick={this.state.closeOnDocumentClick} closeOnEscape={this.state.closeOnEscape} onClose={this.handleCloseModal} onOpen={this.handleOnOpen} className="profileModalPopup">
                {close => (
                    <div className="modal profileModal">
                        <button className="close" onClick={close} />

                        {!notLoaded &&
                            <div style={{ position: 'relative', height: '100%' }}>
                                <div className="header">
                                    <div className="profileImage" style={vipColor}>
                                        {profileImage &&
                                            <div className="profileimageContainer" style={{ backgroundImage: `url(${profileImage})` }}></div>
                                        }
                                        {!profileImage &&
                                            <div className="profileimageContainer"></div>
                                        }
                                    </div>

                                    {!loading &&
                                        <div className="logined">
                                            {userIcon} {this.global.profileModalOpenedUser}
                                            <div className="buttons">
                                                <button onClick={(e) => this.privateOpen(e, this.global.profileModalOpenedUser)}><span className="buttonIcon icon-private" title="Privát üzenet"></span> Privát üzenet</button>
                                                {this.global.moderator &&
                                                    <button className="moderator" onClick={() => this.handleOpenModeratorModal()}><span className="buttonIcon icon-moderator" title="Moderálás"></span> Moderálás</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <Scrollbar noScrollX={true} removeTracksWhenNotUsed={false}>
                                    <div className="content">
                                        {loading &&
                                            <Circle color={'#54a6e0'} background="rgba(0,0,0,1)" animation="fade" />
                                        }
                                        {!loading &&
                                            <div className="userDatas">
                                                <p><label>Név:</label> {this.state.userData.name}</p>
                                                <p><label>Lakhely:</label> {this.state.userData.residence}</p>
                                                <p><label>Születésnap:</label> {this.state.userData.birth}</p>
                                                {this.state.userData.email &&
                                                    <p><label>E-mail:</label> <a href={`mailto: ${this.state.userData.email}`}> {this.state.userData.email}</a></p>
                                                }
                                                {this.state.userData.telephone &&
                                                    <p><label>Telefonszám:</label> {this.state.userData.telephone}</p>
                                                }
                                                {this.state.userData.chattime &&
                                                    <p><label>Elchatelt idő:</label> {this.state.userData.chattime}</p>
                                                }
                                                <p><strong>Bemutatkozás:</strong><br /><br />{this.state.userData.introduction}</p>
                                                <h3>Feltöltött képei:</h3>
                                                <div className="imageList">
                                                <LightboxWrapper onLightboxStateChange={this.handleLightboxStateChange}>
                                                    {userData.images.length === 0 &&
                                                        <div className="image notfound"><img src={noimage} alt="Még nem töltött fel képet!" title="Még nem töltött fel képet!" /></div>
                                                    }
                                                    <ul>
                                                        {userData.images.map((img, index) => (
                                                            <li key={img.id}>
                                                                <a href={img.original}>
                                                                    <img src={img.thumbnail} alt="" />
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </LightboxWrapper>    
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Scrollbar>
                            </div>
                        }
                        {notLoaded &&
                            <div className="notLoaded">
                                <div className="header">Nem sikerült betölteni a felhasználó adatlapját!</div>
                                <div className="image error"><img src={error} alt="Hiba történt" title="Hiba történt" /></div>
                            </div>
                        }
                    </div>
                )}
            </Popup>
        );
    }
}

export default ProfileModal;