import React, { useState, useEffect, useRef } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/styles.css';

function LightboxWrapper({ children, onLightboxStateChange }) {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const slidesArray = [];

    const collectImages = () => {
      const imgElements = containerRef.current.querySelectorAll('a[href] img');

      imgElements.forEach((img) => {
        const parentAnchor = img.closest('a[href]');
        const href = parentAnchor.getAttribute('href');

        if (slidesArray.findIndex(slide => slide.src === href) === -1) {
          slidesArray.push({ src: href });
        }

        // Eseménykezelő hozzáadása
        parentAnchor.addEventListener('click', (e) => {
          e.preventDefault();
          const index = slidesArray.findIndex(slide => slide.src === href);
          setCurrentIndex(index);
          setOpen(true);
          onLightboxStateChange(true);  // Lightbox megnyitása esetén hívás
        });
      });

      setSlides(slidesArray);
    };

    collectImages();

    const observer = new MutationObserver(collectImages);
    observer.observe(containerRef.current, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [children, onLightboxStateChange]);

  return (
    <div ref={containerRef}>
      {children}
      {open && (
        <Lightbox
          open={open}
          close={() => {
            setOpen(false);
            onLightboxStateChange(false);  // Lightbox bezárása esetén hívás
          }}
          slides={slides}
          index={currentIndex}
          on={{
            indexChange: setCurrentIndex,
          }}
          plugins={[Thumbnails]}
          controller={{ closeOnBackdropClick: true }}
        />
      )}
    </div>
  );
}

export default LightboxWrapper;