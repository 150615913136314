import React, { PureComponent } from "reactn";
import Scrollbar from "react-scrollbars-custom";
import clientStorage from "../classes/clientStorage";
import Popup from "reactjs-popup";
import axios from "axios";
//import { Circle } from "react-preloaders";
import deleteprofile from "../images/deleteprofile.svg";
import ReactCodeInput  from "react-code-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordMask from 'react-password-mask';
import 'reactjs-popup/dist/index.css';

class DeleteProfileModal extends PureComponent {
  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.getNewDeleteProfileCode = this.getNewDeleteProfileCode.bind(this);
    this.confirmEmailCode = this.confirmEmailCode.bind(this);

    this.apiUrl = this.global.apiUrl;

    this.state = {
      currentStep: 1,
      errors: [],
      loading: true,
      uploaded: true,
      password: "",
      password2: "",
      emailcode: "",
      images: [],
    };
  }

  changeStep(step) {
    this.setState({ currentStep: step });
  }

  deleteImage(id) {
    let fd = new FormData();
    fd.append("deleteProfileImage", true);
    fd.append("sid", this.global.sid);
    fd.append("id", id);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios
      .post(this.apiUrl + "/deleteProfileImage", fd, { headers })
      .then((res) => {
        console.log(res);

        if (res.data.deleteProfileImage === "ok") {
          //this.getUserData();
        }
      });
  }

  getNewDeleteProfileCode(event) {
    event.preventDefault();
    let fd = new FormData();
    fd.append("getNewCode", true);
    fd.append("password", this.state.password);
    fd.append("password2", this.state.password2);
    fd.append("sid", this.global.sid);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios
      .post(this.apiUrl + "/getNewDeleteProfileCode", fd, { headers })
      .then((res) => {
        console.log(res.data);
        if (res.data.getNewDeleteProfileCode === "ok") {
          this.setState({ emailcode: "" });
          this.setState({ currentStep: 2 });

        } else if (res.data.getNewDeleteProfileCode === "bad syntax") {
          this.setState({ errors: res.data.errors });
        } else {
          this.setState({ errors: res.data.errors });
        }
      });
  }

  confirmEmailCode(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append("confirmCode", true);
    fd.append("code", this.state.emailcode);
    fd.append("sid", this.global.sid);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });

    axios
      .post(this.apiUrl + "/deleteProfileConfirm", fd, { headers })
      .then((res) => {
        if (res.data.confirmCode === "ok") {
          this.setState({ emailcode: "" });
          this.handleCloseModal();
          this.client.disconnect(true);
          this.client.sendAlert(
            "Sikeresen törölted a profilod!",
            "Töröltünk minden személyes adatod is az adatbázisból.",
            "info"
          );

        } else if (res.data.confirmCode === "bad syntax") {
          this.setState({ errors: res.data.errors });
        } else {
          this.setState({ errors: res.data.errors });
        }
      });
  }

  handleOpenModal() {
    this.setState({ opened: true, currentStep: 1, errors: [] });
  }

  handleCloseModal() {
    this.setState({ opened: false, smscode: "", password: "", password2: "" });
  }

  changeInputSettings(event, name) {
    if (name === "password") {
      this.setState({ password: event.target.value });
    } else if (name === "password2") {
      this.setState({ password2: event.target.value });
    } else if (name === "emailcode") {
      this.setState({ emailcode: event });
    }
  }

  render() {
    const buttons = (
      <input
        type="button"
        onClick={this.handleOpenModal}
        value="Regisztráció törlése"
      />
    );
    if (this.state.currentStep === 1) {            
      const showPassword = <FontAwesomeIcon icon={faEye} />
      const hidePassword = <FontAwesomeIcon icon={faEyeSlash} />
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Regisztráció törlése</div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.getNewDeleteProfileCode}>
                    <div className="content">
                      <div className="newpass-image">
                        <img
                          src={deleteprofile}
                          alt="Regisztráció törlése"
                          title="Regisztráció törlése"
                        />
                      </div>
                      <p
                        className="smscode-instruction"
                        style={{ marginBottom: 30 }}
                      >
                        Add meg a profilodhoz tartozó jelszavad és azonnal
                        töröljük a regisztrációdat.
                      </p>

                      <div className="field">
                        <label>Jelszavad:</label>
                        <PasswordMask
                            name="password"
                            value={this.state.password}
                            className="maskedPassword"
                            id="password1"
                            inputClassName={
                              typeof this.state.errors.password !== "undefined"
                                ? "errorBorder"
                                : ""
                            }
                            onChange={(e) =>
                              this.changeInputSettings(e, "password")
                            }
                            placeholder=""
                            buttonClassName="maskedPasswordButton"
                            showButtonContent={showPassword}
                            hideButtonContent={hidePassword}
                          />   
                      </div>

                      {typeof this.state.errors.password !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password}
                        </div>
                      )}

                      <div className="field">
                        <label>Jelszavad újra:</label>
                        <PasswordMask
                            name="password2"
                            id="password2"
                            value={this.state.password2}
                            className="maskedPassword"
                            inputClassName={
                              typeof this.state.errors.password !== "undefined"
                                ? "errorBorder"
                                : ""
                            }
                            onChange={(e) =>
                              this.changeInputSettings(e, "password2")
                            }
                            placeholder=""
                            buttonClassName="maskedPasswordButton"
                            showButtonContent={showPassword}
                            hideButtonContent={hidePassword}
                          /> 
                      </div>

                      {typeof this.state.errors.password2 !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password2}
                        </div>
                      )}

                      <p className="smscode-submit">
                        <button type="button" onClick={this.handleCloseModal}>
                          Bezárás
                        </button>
                        <button type="submit">Mehet</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 2) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Regisztráció törlése </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.confirmEmailCode}>
                    <div className="content">
                      <div className="newpass-image">
                        <img
                          src={deleteprofile}
                          alt="E-mail cím hitelesítése"
                          title="E-mail cím hitelesítése"
                        />
                      </div>
                      <p className="smscode-instruction">
                        Küldtünk neked egy hitelesítő kódot a megadott e-mail
                        címedre.
                        <br />
                        Kérjük írd be a kódot a regisztrációd végleges
                        törléséhez!
                      </p>
                      <div className="field">
                        <div className="smscode">
                          <ReactCodeInput id="emailcode" type='number' fields={6} ref={this.codeRef}
                             value={this.state.emailcode}                          
                             onChange={(e) =>
                              this.changeInputSettings(e, "emailcode")
                            }
                            name="emailcode"                          
                          />
                        </div>
                      </div>
                      {typeof this.state.errors.emailcode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.emailcode}
                        </div>
                      )}
                      <p className="smscode-submit">
                        <button type="submit" className="active">
                          Tovább &#10132;
                        </button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    }
  }
}

export default DeleteProfileModal;
