import { getGlobal } from 'reactn';
import md5 from 'js-md5';
import favoritesController from './favoritesController';

export default class userListController {
	constructor(chatClient) {
		this.chatClient = chatClient;
		this.favoritesController = new favoritesController(this.chatClient);
	}

	returnUserArrayIndex(name) {
		let data = getGlobal().users.slice();
		for (var key in data) {
			if (data[key]['id'] === md5(name)) {
				return key;
			}
		}
		return -1;
	}

	deleteUser(name) {
		if (this.favoritesController.userInFavoriteList(name) === true) {
			this.chatClient.setGlobal({
				customStatusbarText: name+' kilépett.',
				customStatusbarColor: getGlobal().favoriteUserExitColor
			});

			this.chatClient.sendNotification(
				"Figyelem!",
				name+' kilépett.',					
				5000
			);

		}

		const users = getGlobal().users.filter((user) => user.name !== name);
		this.chatClient.setGlobal({ users });
	}

	listItemProcess(res) {
		var that = this;
		var i = 0;
		res.forEach(function (ds) {
			if (i !== 0 && i !== 1) {
				var item = ds.split("\x01");
				if (item[0] !== '' && item[1] !== '') {
					that.appendUsers(item[0], item[1], item[2], item[3], item[4], 'list', item[5]);
				}
			}
			i++;
		});

	}

	appendUsers(name, age, sex, state, icon, type, profileImage) {
		if (type === 'simple' && this.favoritesController.userInFavoriteList(name)) {
		  const favoriteUserJoinColor = getGlobal().favoriteUserJoinColor;
		  this.chatClient.setGlobal({
			customStatusbarText: `${name} belépett.`,
			customStatusbarColor: favoriteUserJoinColor
		  });
		  this.chatClient.sendNotification(
			'Figyelem!',
			`${name} belépett.`,
			5000
		  );
		}
		
		const [sexValue, userId] = sex.split(':');
		const user = {
		  id: md5(name),
		  name,
		  age,
		  sex: sexValue,
		  userid: userId,
		  state,
		  icon,
		  profileImage
		};
		
		const users = [...getGlobal().users, user];
		this.chatClient.setGlobal({ users });
	  }
	  

}