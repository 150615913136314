import { getGlobal } from 'reactn';
import userListController from './userListController';

export default class userStatesController {
	constructor(chatClient) {
		this.chatClient = chatClient;
		this.userListController = new userListController(this);
	}

	changeState(username, state) {
		this.setState(username, state);
	}

	setState(username, state) {
		var uindex = this.userListController.returnUserArrayIndex(username);

		var tabsDiv = document.querySelector(".icon-login");
		if (state === 'disturb') {
			tabsDiv.classList.add("state-disturb");
			tabsDiv.classList.remove("state-normal");
		} else {
			tabsDiv.classList.remove("state-disturb");
			tabsDiv.classList.add("state-normal");
		}

		let users = getGlobal().users.slice();
		users[uindex]['state'] = state;
		this.chatClient.setGlobal({
			users: users
		});

		let tabs = this.chatClient.state.data.slice();
		for (var key in tabs) {
			if (tabs[key]['module'] === 'room') {
				var room = tabs[key]['content'] + 'Users';
				var global = getGlobal();
				var roomUsers = global[room];
				for (var index in roomUsers) {
					if (roomUsers[index]['name'] === username) {
						roomUsers[index]['state'] = state;
						this.chatClient.setGlobal({
							[tabs[key]['content'] + 'Users']: roomUsers
						});
						break;
					}
				}
			}
		}
	}

	changeProfileImage(username, profileImage) {
		var uindex = this.userListController.returnUserArrayIndex(username);

		let users = getGlobal().users.slice();
		users[uindex]['profileImage'] = profileImage;
		this.chatClient.setGlobal({
			users: users
		});

		let tabs = this.chatClient.state.data.slice();
		for (var key in tabs) {
			if (tabs[key]['module'] === 'room') {
				var room = tabs[key]['content'] + 'Users';
				var global = getGlobal();
				var roomUsers = global[room];
				for (var index in roomUsers) {
					if (roomUsers[index]['name'] === username) {
						roomUsers[index]['profileImage'] = profileImage;
						this.chatClient.setGlobal({
							[tabs[key]['content'] + 'Users']: roomUsers
						});
						break;
					}
				}
			}
		}
	}

}