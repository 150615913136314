import React, { PureComponent } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import InputMask from 'react-input-mask';
import Popup from "reactjs-popup";
import axios from 'axios';
import smscode from '../images/smscode.svg';
import newemail from '../images/newemail.svg';
import { Circle } from 'react-preloaders';
import { CheckBox } from 'rc-easyui';
import ReactCodeInput  from "react-code-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordMask from 'react-password-mask';
import 'reactjs-popup/dist/index.css';

class DataModificationModal extends PureComponent {

  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.handleOpenDataModification = this.handleOpenDataModification.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.startModification = this.startModification.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.apiUrl = this.global.apiUrl;

    this.state = {
      name: '',
      email: '',
      defaultEmail: '',
      birthday: '',
      residence: '',
      introduction: '',
      password: '',
      password2: '',
      currentPassword: '',
      emailcode: '',
      smscode: '',
      telephoneAreas: [],
      telephoneArea: '',
      telephone: '',
      defaultTelephone: '',
      from: '',
      currentStep: 1,
      errors: [],
      loading: true,
      changeEmail: false,
      changeTelephone: false
    }

    this.codeRef = React.createRef();
  }

  changeStep(step) {
    this.setState({ currentStep: step });
  }

  getUserData() {
    let fd = new FormData();
    fd.append('getUserData', true);
    fd.append('sid', this.global.sid);
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    this.setState({ errors: {} });
    axios.post(this.apiUrl + '/getUserData', fd, { headers })
      .then(res => {
        this.setState({
          loading: false,
          email: res.data.results.email,
          defaultEmail: res.data.results.email,
          name: res.data.results.name,
          residence: res.data.results.residence,
          introduction: res.data.results.introduction,
          birthday: res.data.results.birth,
          telephoneArea: '+'+res.data.results.telephone_area,
          telephone: res.data.results.telephone,
          defaultTelephone: res.data.results.telephone,
          email_pub: !!parseInt(res.data.results.email_pub),
          telephone_pub: !!parseInt(res.data.results.telephone_pub)
        });

      })
  }

  getTelephoneAreas() {
    let fd = new FormData();
    fd.append('getTelephoneAreas', true);
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    this.setState({ errors: {} });
    axios.post(this.apiUrl + '/getTelephoneAreas', fd, { headers })
      .then(res => {
        this.setState({
          telephoneAreas: res.data.telephoneAreas,
          telephoneArea: res.data.telephoneAreas[0],
        });
      })
  }

  changeCheckboxes(checked, type) {
    if (type === 'email_pub') {
      this.setState({ email_pub: checked });
    } else if (type === 'telephone_pub') {
      this.setState({ telephone_pub: checked });
    } else if (type === 'changeEmail') {

      if (checked === true) {
        this.setState({ changeEmail: checked, changeTelephone: false, telephone: this.state.defaultTelephone, currentPassword: '' });
      } else {
        this.setState({ changeEmail: checked, email: this.state.defaultEmail });
      }

    } else if (type === 'changeTelephone') {
      if (checked === true) {
        this.setState({ changeTelephone: checked, changeEmail: false, email: this.state.defaultEmail, currentPassword: '' });
      } else {
        this.setState({ changeTelephone: checked, telephone: this.state.defaultTelephone });
      }
    }
  }

  handleOpenDataModification() {
    this.getUserData();
    this.getTelephoneAreas();
    this.setState({ opened: true, currentStep: 1, currentPassword: '', changeEmail: false, changeTelephone: false, errors: [] });
  }

  handleCloseModal() {
    this.setState({ opened: false, smscode: '', password: '', password2: '' });
  }

  changeInputSettings(event, name) {
    if (name === 'username') {
      this.setState({ username: event.target.value })
    } else if (name === 'password') {
      this.setState({ password: event.target.value })
    } else if (name === 'password2') {
      this.setState({ password2: event.target.value })
    } else if (name === 'email') {
      this.setState({ email: event.target.value })
    } else if (name === 'birthday') {
      this.setState({ birthday: event.target.value })
    } else if (name === 'telephoneArea') {
      this.setState({ telephoneArea: event.target.value })
    } else if (name === 'telephone') {
      this.setState({ telephone: event.target.value })
    } else if (name === 'name') {
      this.setState({ name: event.target.value })
    } else if (name === 'emailcode') {
      this.setState({ emailcode: event })
    } else if (name === 'smscode') {
      this.setState({ smscode: event})
    } else if (name === 'introduction') {
      this.setState({ introduction: event.target.value })
    } else if (name === 'residence') {
      this.setState({ residence: event.target.value })
    } else if (name === 'currentPassword') {
      this.setState({ currentPassword: event.target.value })
    }
  }

  startModification(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append('update', true);
    fd.append('sid', this.global.sid);
    fd.append('name', this.state.name);
    fd.append('password', this.state.password);
    fd.append('password2', this.state.password2);
    fd.append('email', this.state.email);
    fd.append('email_pub', Number(this.state.email_pub));
    fd.append('birthday', this.state.birthday);
    fd.append('telephone', this.state.telephone);
    fd.append('telephoneArea', this.state.telephoneArea);
    fd.append('telephone_pub', Number(this.state.telephone_pub));
    fd.append('introduction', this.state.introduction);
    fd.append('residence', this.state.residence);
    fd.append('currentPassword', this.state.currentPassword);

    if (this.state.currentStep === 2) {
      fd.append('emailcode', this.state.emailcode);
    }

    if (this.state.currentStep === 3) {
      fd.append('smscode', this.state.smscode);
    }

    fd.append('from', this.state.from);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    this.setState({ errors: {} });
    axios.post(this.apiUrl + '/dataModification', fd, { headers })
      .then(res => {
        if (res.data.dataModification === 'error') {
          this.setState({ errors: res.data.errors });
        } else if (res.data.dataModification === 'wait') {
          this.client.sendAlert('Figyelem!', res.data.message, 'warning');
        } else if (res.data.dataModification === 'ok') {
          this.client.sendAlert('Sikeresen módosítottad az adataid!', '', 'success');
          this.handleCloseModal();
        } else if (res.data.dataModification === 'email-confirmation') {          
          this.changeStep(2);
          this.clearPin();
        } else if (res.data.dataModification === 'sms-confirmation') {
          this.changeStep(3);
          this.clearPin();
        }
      })
  }

  clearPin() {
    if(this.codeRef.current.textInput[0]) this.codeRef.current.textInput[0].focus()
    this.codeRef.current.state.input[0] = ""
    this.codeRef.current.state.input[1] = ""
    this.codeRef.current.state.input[2] = ""
    this.codeRef.current.state.input[3] = ""
    this.codeRef.current.state.input[4] = ""
    this.codeRef.current.state.input[5] = ""
  }

  render() {
    const buttons = <input type="button" onClick={this.handleOpenDataModification} value="Adatmódosítás" />
    if (this.state.currentStep === 1) {
      
      const showPassword = <FontAwesomeIcon icon={faEye} />
      const hidePassword = <FontAwesomeIcon icon={faEyeSlash} />

      return (
        <div>
          {buttons}
          <Popup open={this.state.opened} closeOnDocumentClick={false} onClose={this.handleCloseModal}>
            {close => (
              <div className="modal">

                {this.state.loading === true &&
                  <Circle color={'#54a6e0'} background="rgba(0,0,0,1)" animation="fade" />
                }

                <button className="close" onClick={close} />
                <div className="header"> Adatmódosítás </div>

                {this.state.loading === false &&
                  <Scrollbar noScrollX={true}>
                    <form onSubmit={this.startModification}>

                      <div className="content">
                        <div className="formItemSeparator">Alapadatok</div>

                        <div className="field">
                          <label>Neved:</label>
                          <input type="text" value={this.state.name} className={(typeof this.state.errors.name !== 'undefined') ? 'errorBorder' : ''} name="name" onChange={(e) => this.changeInputSettings(e, 'name')} placeholder="pl: Teszt Péter" />
                        </div>

                        {typeof this.state.errors.name !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.name}</div>
                        }

                        <div className="field">
                          <label>Lakhelyed:</label>
                          <input type="text" value={this.state.residence} className={(typeof this.state.errors.residence !== 'undefined') ? 'errorBorder' : ''} name="residence" onChange={(e) => this.changeInputSettings(e, 'residence')} placeholder="pl: Budapest" />
                        </div>

                        {typeof this.state.errors.from !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.residence}</div>
                        }

                        <div className="field">
                          <label>Születésnapod:</label>
                          <div className="formItem check">                    
                              <InputMask
                              name="birthday"
                              value={this.state.birthday}
                              className={
                                typeof this.state.errors.birthday !== "undefined"
                                  ? "errorBorder masked"
                                  : "masked"
                              }
                              onChange={(e) =>
                                this.changeInputSettings(e, "birthday")
                              }                          
                              mask="9999-99-99" 
                              maskChar="_" 
                              placeholder="____-__-__"
                              />
                          </div>
                        </div>

                        {typeof this.state.errors.birthday !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.birthday}</div>
                        }

                        <div className="formItemSeparator">Jelszómódosítás</div>

                        <div className="field">
                              <label>Jelenlegi jelszavad:</label>
                        
                              <PasswordMask
                                name="currentPassword"
                                value={this.state.currentPassword}
                                className="maskedPassword"
                                id="password0"
                                inputClassName={
                                  typeof this.state.errors.currentPassword !== "undefined"
                                    ? "errorBorder"
                                    : ""
                                }
                                onChange={(e) =>
                                  this.changeInputSettings(e, "currentPassword")
                                }
                                placeholder=""
                                buttonClassName="maskedPasswordButton"
                                showButtonContent={showPassword}
                                hideButtonContent={hidePassword}
                              />
                        
                        
                        </div>

                        {typeof this.state.errors.currentPassword !== 'undefined' &&
                              <div className="fieldError">{this.state.errors.currentPassword}</div>
                        }

                        <div className="field">
                          <label>Új jelszó:</label>
                      
                          <PasswordMask
                            name="password"
                            value={this.state.password}
                            className="maskedPassword"
                            id="password1"
                            inputClassName={
                              typeof this.state.errors.password !== "undefined"
                                ? "errorBorder"
                                : ""
                            }
                            onChange={(e) =>
                              this.changeInputSettings(e, "password")
                            }
                            placeholder=""
                            buttonClassName="maskedPasswordButton"
                            showButtonContent={showPassword}
                            hideButtonContent={hidePassword}
                          />                   
                        </div>

                        {typeof this.state.errors.password !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.password}</div>
                        }

                        <div className="field">
                          <label>Új jelszó újra:</label>
                            <PasswordMask
                            name="password2"
                            id="password2"
                            value={this.state.password2}
                            className="maskedPassword"
                            inputClassName={
                              typeof this.state.errors.password !== "undefined"
                                ? "errorBorder"
                                : ""
                            }
                            onChange={(e) =>
                              this.changeInputSettings(e, "password2")
                            }
                            placeholder=""
                            buttonClassName="maskedPasswordButton"
                            showButtonContent={showPassword}
                            hideButtonContent={hidePassword}
                          />                        
                        </div>

                        {typeof this.state.errors.password2 !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.password2}</div>
                        }

                        <div className="formItemSeparator">E-mail cím, telefonszám módosítása</div>

                        <div className="field">
                          <div className="formItem check">
                            <div className="left"><CheckBox inputId="changeEmail" name="changeEmail" checked={this.state.changeEmail} onChange={(checked) => this.changeCheckboxes(checked, 'changeEmail')} ></CheckBox></div>
                            <div className="right"><label htmlFor="changeEmail">Szeretném megváltoztatni az E-mail címem</label></div>
                          </div>
                        </div>

                        {this.state.changeEmail === true &&
                          <div>
                            <div className="field">
                              <label>Jelenlegi jelszavad:</label>
                            
                              <PasswordMask
                                name="currentPassword"
                                value={this.state.currentPassword}
                                className="maskedPassword"
                                id="password3"
                                inputClassName={
                                  typeof this.state.errors.currentPassword !== "undefined"
                                    ? "errorBorder"
                                    : ""
                                }
                                onChange={(e) =>
                                  this.changeInputSettings(e, "currentPassword")
                                }
                                placeholder=""
                                buttonClassName="maskedPasswordButton"
                                showButtonContent={showPassword}
                                hideButtonContent={hidePassword}
                              />
                            
                            </div>

                            {typeof this.state.errors.currentPassword !== 'undefined' &&
                              <div className="fieldError">{this.state.errors.currentPassword}</div>
                            }
                            <div className="field">
                              <label>E-mail címed:</label>
                              <input type="text" name="email" value={this.state.email} className={(typeof this.state.errors.email !== 'undefined') ? 'errorBorder' : ''} onChange={(e) => this.changeInputSettings(e, 'email')} placeholder="pl: teszt.peter@gmail.com" />
                            </div>

                            {typeof this.state.errors.email !== 'undefined' &&
                              <div className="fieldError">{this.state.errors.email}</div>
                            }
                          </div>
                        }              

                        <div className="field">
                          <div className="formItem check">
                            <div className="left"><CheckBox inputId="changeTelephone" name="changeTelephone" checked={this.state.changeTelephone} onChange={(checked) => this.changeCheckboxes(checked, 'changeTelephone')} ></CheckBox></div>
                            <div className="right"><label htmlFor="changeTelephone">Szeretném megváltoztatni a telefonszámom</label></div>
                          </div>
                        </div>

                        {this.state.changeTelephone === true &&
                          <div>
                            <div className="field">
                              <label>Jelenlegi jelszavad:</label>
                              <PasswordMask
                                name="currentPassword"
                                value={this.state.currentPassword}
                                className="maskedPassword"
                                id="password4"
                                inputClassName={
                                  typeof this.state.errors.currentPassword !== "undefined"
                                    ? "errorBorder"
                                    : ""
                                }
                                onChange={(e) =>
                                  this.changeInputSettings(e, "currentPassword")
                                }
                                placeholder=""
                                buttonClassName="maskedPasswordButton"
                                showButtonContent={showPassword}
                                hideButtonContent={hidePassword}
                              />
                            </div>
                            {typeof this.state.errors.currentPassword !== 'undefined' &&
                              <div className="fieldError">{this.state.errors.currentPassword}</div>
                            }
                            <div className="field">
                              <label>Telefonszámod:</label>
                              <div className="formItem check telephone">
                                <select value={this.state.telephoneArea} className={(typeof this.state.errors.telephoneArea !== 'undefined') ? 'errorBorder' : ''} onChange={(e) => this.changeInputSettings(e, 'telephoneArea')} >
                                  {
                                    this.state.telephoneAreas.map((item, index) => {
                                      return <option value={item}>{item}</option>
                                    })
                                  }
                                </select>

                                <InputMask
                                  name="telephone"
                                  value={this.state.telephone}
                                  className={
                                    typeof this.state.errors.telephone !== "undefined"
                                      ? "errorBorder masked"
                                      : "masked"
                                  }
                                  onChange={(e) =>
                                    this.changeInputSettings(e, "telephone")
                                  }                          
                                  mask="9999999999" 
                                  maskChar="" 
                                  placeholder=""
                                  /> 
                              
                              </div>
                            </div>
                          </div>
                        }

                        {typeof this.state.errors.telephone !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.telephone}</div>
                        }

                        {typeof this.state.errors.telephoneArea !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.telephoneArea}</div>
                        }

                        <div className="formItemSeparator">Megjelenési beállítások</div>


                        <div className="field">
                          <div className="formItem check">
                            <div className="left"><CheckBox inputId="email_pub" name="email_pub" checked={this.state.email_pub} onChange={(checked) => this.changeCheckboxes(checked, 'email_pub')} ></CheckBox></div>
                            <div className="right"><label htmlFor="email_pub" className={(typeof this.state.errors.telephone_pub !== 'undefined') ? 'errorColor' : ''}>Megjelenhet az E-mail címed az adatlapodon?</label></div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="formItem check">
                            <div className="left"><CheckBox inputId="telephone_pub" name="telephone_pub" checked={this.state.telephone_pub} onChange={(checked) => this.changeCheckboxes(checked, 'telephone_pub')} ></CheckBox></div>
                            <div className="right"><label htmlFor="telephone_pub" className={(typeof this.state.errors.telephone_pub !== 'undefined') ? 'errorColor' : ''}>Megjelenhet a telefonszámod az adatlapodon?</label></div>
                          </div>
                        </div>

                        <div className="formItemSeparator">Írj magadról</div>

                        <div className="field">
                          <label>Bemutatkozás:</label>
                          <div className="formItem">
                            <textarea name="introduction" value={this.state.introduction} onChange={(e) => this.changeInputSettings(e, 'introduction')}></textarea>
                          </div>
                        </div>
                        {typeof this.state.errors.birthday !== 'undefined' &&
                          <div className="fieldError">{this.state.errors.introduction}</div>
                        }
                        <p className="submit field">
                          <button type="submit" className="active">Mentés</button>
                        </p>
                      </div>
                    </form>

                  </Scrollbar>
                }
              </div>
            )}

          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 2) {
      return (
        <div>
          {buttons}
          <Popup open={this.state.opened} closeOnDocumentClick={false} onClose={this.handleCloseModal}>
            {close => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header">Adatmódosítás</div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.startModification}>
                    <div className="content">
                      <div className="smscode-image"><img src={newemail} alt="E-mail cím hitelesítése" title="E-mail cím hitelesítése" /></div>
                      <p className="smscode-instruction">Küldtünk neked egy hitelesítő kódot a megadott e-mail címre.<br />Kérjük írd be a kódot az adataid megváltoztatásához!</p>
                      <div className="field">
                        <div className="smscode">                       
                          <ReactCodeInput id="emailcode-2" type='number' fields={6} ref={this.codeRef}
                             value={this.state.emailcode}                          
                             onChange={(e) =>
                              this.changeInputSettings(e, "emailcode")
                            }
                            name="emailcode"                          
                          />
                        </div>
                      </div>
                      {typeof this.state.errors.emailcode !== 'undefined' &&
                        <div className="fieldError">{this.state.errors.emailcode}</div>
                      }
                      <p className="smscode-submit">
                        <button type="submit" className="active">Tovább &#10132;</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 3) {
      return (
        <div>
          {buttons}
          <Popup open={this.state.opened} closeOnDocumentClick={false} onClose={this.handleCloseModal}>
            {close => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header">Adatmódosítás</div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.startModification}>
                    <div className="content">
                      <div className="smscode-image"><img src={smscode} alt="E-mail cím hitelesítése" title="E-mail cím hitelesítése" /></div>
                      <p className="smscode-instruction">Küldtünk neked egy hitelesítő kódot a megadott telefonszámra.<br />Kérjük írd be a kódot az adataid megváltoztatásához!</p>
                      <div className="field">
                        <div className="smscode">
                          <ReactCodeInput id="smscode-2" type='number' fields={6} ref={this.codeRef}
                             value={this.state.smscode}
                             onChange={(e) =>
                              this.changeInputSettings(e, "smscode")
                            }
                            name="smscode"                          
                          />
                          </div>
                      </div>
                      {typeof this.state.errors.smscode !== 'undefined' &&
                        <div className="fieldError">{this.state.errors.smscode}</div>
                      }
                      <p className="smscode-submit">
                        <button type="submit" className="active">Tovább &#10132;</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    }
  }
}


export default DataModificationModal;