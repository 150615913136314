import React, { PureComponent, setGlobal } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import TimeAgo from "javascript-time-ago";
import hu from "javascript-time-ago/locale/hu";

TimeAgo.addLocale(hu);

class Notifications extends PureComponent {

	constructor(props) {
		super(props);
		this.client = clientStorage.id;

		this.state = {
			logintext: 'Bejelentkezés',
            rendered: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.global.selectedTab === 'notifications') {
		  if (this.state.rendered === false) {
			this.setState({
			  rendered: true,
			});
            this.client.showLoading();
			setTimeout(() => this.client.hideLoading(), 300);
		  }
		}
	}

    clear() {
        setGlobal({
			notifications: []
        })
    }

    deleteItem(id) {
		let data = this.global.notifications;
		for (var key in data) {
			if (data[key]['id'] === id) {
				data.splice(key, 1);
			}
        }
        this.client.setGlobal({
            notifications: data
        })
    }

    getShortTime(unixTimestamp) {
        var dt = new Date(unixTimestamp * 1000);
        var hr = dt.getHours();
        var m = "0" + dt.getMinutes();
        if (hr < 10) {
          hr = "0" + hr;
        }
        return hr + ":" + m.substr(-2);
    }

    render() {
        if (this.state.rendered === true) {

            let elapsedTime;      
            let date;
            const timeAgo = new TimeAgo("hu-HU");
            return (
                <div className="chatContainerFull" id="notifications">
                    <Scrollbar noScrollX={true}>
                        <div>
                        <h2 className="moduleTitle"><div className="moduleIcon icon-rooms"></div> Értesítések</h2>

                            <div className="notification-box">
                                <button onClick={() => this.clear()}>Törlöm mindet <FontAwesomeIcon icon={faTrashAlt} /></button>   
                                <ul>
                                {
                                    this.global.notifications.map((arr, index) => {
                                    elapsedTime = timeAgo.format(new Date(arr.time * 1000));
                                    date = new Date(arr.time * 1000);
                                    return <li key={arr.id}><div><p>{arr.message}</p><p className="notificationTime">{elapsedTime}, {date.toLocaleDateString("hu-HU")} {this.getShortTime(arr.time)}</p></div> <button onClick={() => this.deleteItem(arr.id)}><FontAwesomeIcon icon={faTimes} /></button>  </li>
                                    })
                                }
                                </ul>


                                {this.global.notifications.length === 0 &&

                                    <div className="empty">
                                        Nincs egy értesítésed sem.
                                    </div>
                                }
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            );
        } else {
            return <div>{this.global.selectedTab}</div>;
        }
	}
}



//{ this.props.client.messages.room.map((tab,index) => (
//         <p>{tab.mess}</p>
//       ))
//}   

export default Notifications;