import { getGlobal } from 'reactn';
import md5 from 'js-md5';

export default class ignoresController {
	constructor(chatClient) {
		this.chatClient = chatClient;
	}

	returnUserArrayIndex(name) {
		let data = getGlobal().ignores.slice();
		for (var key in data) {
			if (data[key]['id'] === md5(name)) {
				return key;
			}
		}
		return -1;
	}

	deleteUser(name) {
		let ignores = getGlobal().ignores;
		var index = this.returnUserArrayIndex(name);
		if (index !== -1) {
			ignores.splice(index, 1);
			this.chatClient.setGlobal({
				ignores: ignores
			});
		}
	}


	listItemProcess(res) {
		var that = this;
		var i = 0;
		res.forEach(function (ds) {
			if (i !== 0) {
				var item = ds.split("\x01");
				item.forEach(function (user) {
					if (user !== '') {
					that.appendUsers(user);
					}
				});
			}
			i++;
		});

	}

	userInIgnoreList(username) {
		const data = getGlobal().ignores;
		for (const item of data) {
			if (item.name === username) {
				return true;
			}
		}
		return false;
	}
	

	appendUsers(name) {		
		if (this.userInIgnoreList(name) === false) {
			let users = getGlobal().ignores.slice();

			this.chatClient.setGlobal({
				ignores: users
			})
			var user = {
				id: md5(name),
				name: name,
			};

			users.push(user);
			this.chatClient.setGlobal({
				ignores: users
			})
		}
	}
}