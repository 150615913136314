import { getGlobal } from 'reactn';
import md5 from 'js-md5';
import shortid from "shortid";
import addNotification from 'react-push-notification';

export default class privateController {
	constructor(chatClient) {
		this.chatClient = chatClient;
	}

	getDatetime() {
		var dt = new Date();
		var hr = dt.getHours();
		var m = dt.getMinutes();

		if (hr < 10) {
			hr = '0' + hr;
		}
		if (m < 10) {
			m = '0' + m;
		}
		return hr + ':' + m;
	}

	privateAdd(res) {

		if (this.chatClient.tabController.existsRoom(res[1]) === false) {

			if (this.chatClient.tabController.existsPrivateByName(res[2])) {
				this.chatClient.tabController.changePrivateIdByName(res[2],res[1]);
			} else {

				this.chatClient.setGlobal({
					[res[1] + 'Messages']: [],
					[res[1] + 'Users']: [],
				})

				if (res[4] !== '') {
					this.createClass('private-'+res[1], 'background-image: url('+getGlobal().profileImageUrl+'/'+res[4]+'.jpg); border-radius: 100%');
					this.chatClient.tabController.addTab('private', res[2], res[1], 'privateTab private-'+res[1]);
				} else {
					this.chatClient.tabController.addTab('private', res[2], res[1], 'privateTab icon-private');
				}

				this.appendServerMessage(res[1], 'A csevegés elkezdődött.');

				if (getGlobal().logined) {
					if (res[3] === "owner" || res[3] === "exists") {
						setTimeout(() => this.chatClient.tabController.selectPrivateTab(res[1]));
					}	
				}			

				if (getGlobal().user.autoMessage !== "" && res[3] !== "owner" && res[3] !== "exists") {
					this.chatClient.sendMessage('privatemsg\x00' + res[1] + '\x00' + getGlobal().user.autoMessage);
				}
			}
		}
	}

	createClass(name,rules){
		var style = document.createElement('style');
		style.type = 'text/css';
		style.innerHTML = '.'+name+' { '+rules+' }';
		document.getElementsByTagName('head')[0].appendChild(style);		
		//document.getElementById('someElementId').className = 'cssClass';
	}

	processHistory(roomid, array) {
		var store = getGlobal();
		let roomMessages = store[roomid + 'Messages'];

		var i = 0;
		for (var key in array) {
			var data = array[key].split("\x01");
			if (data[0] !== '' && i > 2) {

				var username = data[0];
				var color = data[1];
				var rmessage = data[2];
				var time = data[3];
				var messageItem = [];

				messageItem = {
					id: shortid.generate(),
					username: username,
					userid: md5(username),
					message: rmessage,
					color: color,
					time: time
				};
				roomMessages.push(messageItem);
			}
			i++;
		}

		this.chatClient.setGlobal({
			[roomid + 'Messages']: roomMessages
		});

	}

	appendMessage(roomid, username, color, rmessage, profileImage, vipColor, messageID) {
		var store = getGlobal();
		let roomMessages = store[roomid + 'Messages'];
		var messageItem = [];
		var roomIndex = this.getRoomIndexByID(roomid);

		if (this.chatClient.state.selectedIndex !== roomIndex) {
			setTimeout(() => this.chatClient.playSoundNotification());
			setTimeout(() => this.chatClient.tabController.changeColorByIndex(roomIndex, store.tabNewPrivateMessageColor));

			if (username !== getGlobal().username) {
				addNotification({
					title: 'Új privát üzenet! - ' + getGlobal().appName,
					subtitle: username + ': ' + rmessage,
					message: username + ': ' + rmessage,
					theme: 'darkblue',
					native: true
				});
			}
		} else {
			this.chatClient.sendMessage("private_seen\x00" + roomid);
			this.chatClient.tabController.noColorByIndex(roomIndex);
		}

		var currentLength = roomMessages.length;
		var messageLimit = store.roomMessageLimit;
		if (currentLength >= messageLimit) {
			var delLines = currentLength - messageLimit;
			roomMessages.splice(0, delLines);
		}
		messageItem = {
			id: messageID,
			roomid: roomid,
			username: username,
			userid: md5(username),
			message: rmessage,
			color: color,
			time: getGlobal().serverTime,
			serverMessage: false,
			profileImage: profileImage,
			vipColor: vipColor

		};
		roomMessages.push(messageItem);
		this.chatClient.setGlobal({
			[roomid + 'Messages']: roomMessages
		});
	}

	appendServerMessage(roomid, rmessage, color = 'C2C3C8') {
		var store = getGlobal();
		let roomMessages = store[roomid + 'Messages'];
		var messageItem = [];

		var currentLength = roomMessages.length;
		var messageLimit = store.roomMessageLimit;
		if (currentLength >= messageLimit) {
			var delLines = currentLength - messageLimit;
			roomMessages.splice(0, delLines);
		}
		messageItem = {
			id: shortid.generate(),
			roomid: roomid,
			username: '',
			userid: '',
			message: rmessage,
			color: color,
			time: getGlobal().serverTime,
			serverMessage: true
		};
		roomMessages.push(messageItem);
		this.chatClient.setGlobal({
			[roomid + 'Messages']: roomMessages
		});
	}

	processUsers(roomid, users) {
		let roomUsers =[];
		var data = users.split("\x01");
		for (var key in data) {
			var name = data[key];
			var userItem = [];
			userItem = {
				id: shortid.generate(),
				name: name
			};
			roomUsers.push(userItem);
		}
		this.chatClient.setGlobal({
			[roomid + 'Users']: roomUsers
		})
	}

	getRoomItemByID(roomid) {
		let rooms = getGlobal().rooms.slice();
		for (var key in rooms) {
			var roomData = rooms[key];
			for (var rdKey in roomData) {
				var roomDataItem = roomData[rdKey];
				for (var cKey in roomDataItem) {
					var roomItem = roomDataItem[cKey];
					if (roomItem['id'] === roomid) {
						return roomItem;
					}
				}
			}
		}
	}

	getRoomIndexByID(roomid) {
		let data = this.chatClient.state.data.slice();
		var tabIndex = null;
		for (var key in data) {
			if (data[key]['content'] === roomid) {
				tabIndex = parseInt(key);
			}
		}
		return tabIndex;
	}

	setTopic(roomid, topic, user) {
		var store = getGlobal();
		let topics = store.topics;
		var topicItem = [];
		topicItem = {
			roomid: roomid,
			text: topic,
			user: user
		};
		topics.push(topicItem);
		this.chatClient.setGlobal({
			topics: topics
		});
	}

	clearPrivates() {
		let data = this.chatClient.state.data.slice();
		for (var key in data) {
			if (data[key]['module'] === 'private') {
				this.chatClient.setGlobal({
					[data[key]['content'] + 'Messages']: [],
					[data[key]['content'] + 'Users']: [],
					// [data[key]['content'] + 'Settings']: []
				})
			}
		}
	}

	freezePrivates() {
		let data = this.chatClient.state.data.slice();
		let freezed = getGlobal().freezedPrivates.slice();

		for (var key in data) {
			if (data[key]['module'] === 'private') {
				if (this.isFreezedPrivate(data[key]['content']) === false) {
					freezed.push({id: data[key]['content']});
				}
			}
		}

		this.chatClient.setGlobal({
			freezedPrivates: freezed
		})
	}


	isFreezedPrivate(roomid) {
		let data = getGlobal().freezedPrivates.slice();
		for (var key in data) {
			if (data[key]['id'] === roomid) {
				return true;
			}
		}
		return false;
	}

	delFreezedPrivate(roomid) {
		let data = getGlobal().freezedPrivates.slice();
		for (var key in data) {
			if (data[key]['id'] === roomid) {
				data.splice(key, 1);
			}
		}
		this.chatClient.setGlobal({
			freezedPrivates: data
		})
	}

	privateNotExists(roomid, message) {
		let data = this.chatClient.state.data.slice();
		for (var key in data) {
			if (data[key]['module'] === 'private' && data[key]['content'] === roomid) {
				this.chatClient.sendMessage("private_join\x00" + data[key]['title']+"\x00"+message);
			}
		}
	}

	reconnectPrivates() {
		let data = this.chatClient.state.data.slice();
		for (var key in data) {
			if (data[key]['module'] === 'private') {
				this.chatClient.sendMessage("private_join\x00" + data[key]['title']);
			}
		}
	}

	checkSeen(index) {
		if (this.chatClient.state.data[index].module === 'private') {
			this.chatClient.sendMessage("private_seen\x00" + this.chatClient.state.data[index].content);
			this.chatClient.tabController.noColorByIndex(index);
		}			
	}

	notify(privateID) {
		var roomIndex = this.getRoomIndexByID(privateID);
		setTimeout(() => this.chatClient.tabController.changeColorByIndex(roomIndex, getGlobal().tabNewPrivateMessageColor));
	}


}