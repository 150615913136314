import shortid from "shortid";
import { getGlobal } from "reactn";

export default class tabController {
  constructor(chatClient) {
    this.chatClient = chatClient;
  }

  addTab(module, title, content, icon) {
    if (module !== "room" && module !== "private") {
      if (this.exists(module)) {
        return;
      }
    } else {
      if (this.existsRoom(content)) {
        return;
      }
    }

    let data = this.chatClient.state.data.slice();

    let order = 0;
    if (module === "room") {
      order = 1000;
    }

    data.push({
      id: shortid.generate(),
      title: title,
      content: content,
      module: module,
      icon: icon,
      closed: false,
      order:order,
      className: "teszt"
    });
    let index = data.length - 1;

    this.chatClient.setState({
      data: data,
    });

    if (this.chatClient.state.selectedIndex !== index) {
      this.noColorByIndex(this.chatClient.state.selectedIndex);

      if (
        module !== "private" &&
        module !== "settings" &&
        module !== "userList" &&
        module !== "notifications" &&
        module !== "roomList"
      ) {
        if (getGlobal().logined) {
          setTimeout(
            () => this.chatClient.setState({ selectedIndex: index }),
            50
          );
        }
      }
    }
  }

  delTab(module) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {
        data.splice(key, 1);
      }
    }
    this.chatClient.setState({
      data: data,
    });
  }

  exists(module) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {
        return true;
      }
    }
    return false;
  }

  existsRoom(content) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["content"] === content) {
        return true;
      }
    }
    return false;
  }

  existsPrivateByName(name) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === "private" && data[key]["title"] === name) {
        return true;
      }
    }
    return false;
  }

  changePrivateIdByName(name, newID) {
    let store = getGlobal();
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === "private" && data[key]["title"] === name) {
        let oldID = data[key]["content"];
        data[key]["content"] = newID;

        this.chatClient.setGlobal({
          [newID + "Messages"]: store[oldID + "Messages"],
          [oldID + "Messages"]: [],
        });
      }
    }
    this.chatClient.setState({ data: data });
  }

  delRoomTabpanel(module, roomid) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module && data[key]["content"] === roomid) {
        data[key]["closed"] = true;
        this.chatClient.setState({
          data: data,
        });
        return true;
      }
    }
    return false;
  }

  delRoomTab(module, roomid) {
    //this.delRoomTabpanel(module, roomid);
    this.clearRoomData(roomid);
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module && data[key]["content"] === roomid) {
        this.chatClient.setGlobal({
          [data[key]["content"] + "Messages"]: [],
          [data[key]["content"] + "Users"]: [],
        });

        data.splice(key, 1);
        this.chatClient.setState({
          data: data,
        });

        //FIXME: ideIglenes megoldás, máshogy nem sikerült elérni, hogy frissítsen
        let index = data.length - 1;
        this.chatClient.setState({
          selectedIndex: 0,
        });
        this.chatClient.setState({
          selectedIndex: index,
        });

        return true;
      }
    }

    return false;
  }

  delPrivateTab(module, privateID) {
    //this.delRoomTabpanel(module, privateID);
    this.clearPrivateData(privateID);
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (
        data[key]["module"] === module &&
        data[key]["content"] === privateID
      ) {
        data.splice(key, 1);
        this.chatClient.setState({
          data: data,
        });

        //FIXME: ideIglenes megoldás, máshogy nem sikerült elérni, hogy frissítsen
        let index = data.length - 1;
        this.chatClient.setState({
          selectedIndex: 0,
        });
        this.chatClient.setState({
          selectedIndex: index,
        });
        return true;
      }
    }

    return false;
  }

  clearPrivateData(privateID) {
    this.chatClient.setGlobal({
      [privateID + "Messages"]: [],
      [privateID + "Users"]: [],
    });
  }

  clearRoomData(roomid) {
    this.chatClient.setGlobal({
      [roomid + "Messages"]: [],
      [roomid + "Users"]: [],
    });
  }

  delAllTab(module) {
    var data = {};
    data = [
      {
        id: "login",
        title: "Belépés",
        content: "",
        module: "login",
        icon: "icon-login",
        closed: false,
      },
    ];
    this.chatClient.setState({
      data: data,
    });
  }

  renameTab(module, name) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {
        data[key]["title"] = name;
      }
    }
    this.chatClient.setState({
      data: data,
    });
  }

  selectTab(module) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {
        this.chatClient.state.selectedIndex = parseInt(key);
        return true;
      }
    }
    return false;
  }

  selectModuleTab(module) {
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["module"] === module) {

        console.log('selectModuleTab: '+module)
        let tabIndex = key;
        setTimeout(
          () => this.chatClient.setState({ selectedIndex: parseInt(tabIndex) }),
          0
        );

      //  this.chatClient.setState({ selectedIndex: parseInt(tabIndex) })
        return true;
      }
    }
    return false;
  }

  selectRoomTab(roomid) {
    this.noColorByIndex(this.chatClient.state.selectedIndex);
    // this.chatClient.setState({
    // 	selectedIndex: 0
    // });
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["content"] === roomid) {
        let tabIndex = key;
        setTimeout(
          () => this.chatClient.setState({ selectedIndex: parseInt(tabIndex) }),
          50
        );
        return true;
      }
    }
    return false;
  }

  selectPrivateTab(privateID) {
    this.noColorByIndex(this.chatClient.state.selectedIndex);
    this.chatClient.setState({
      selectedIndex: 0,
    });
    let data = this.chatClient.state.data.slice();
    for (var key in data) {
      if (data[key]["content"] === privateID) {
        this.chatClient.setState({
          selectedIndex: parseInt(key),
        });
        return true;
      }
    }
    return false;
  }

  changeColor(module, color) {
    const data = this.chatClient.state.data.slice();
    let tabIndex = null;

    for (const [index, item] of data.entries()) {
        if (item["module"] === module) {
            tabIndex = index;
            break;
        }
    }

    if (tabIndex !== null) {
        const tabQuery = ".tabs-header-left .tabs li .tabs-inner";
        const tabTextQuery = ".tabs-header-left .tabs li .tabs-inner .tabs-title";
        
        const tab = document.querySelectorAll(tabQuery);
        const tabText = document.querySelectorAll(tabTextQuery);
        
        if (typeof tab !== "undefined" && tab.length > tabIndex) {
            try {
                tab[tabIndex].style.borderRight = `4px solid ${color}`;
                tabText[tabIndex].style.color = color;
            } catch (err) {
                console.error("Error changing color: ", err);
            }
        }
    }
}


  changeColorByIndex(index, color) {
    var tab = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner"
    );
    var tabText = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner .tabs-title"
    );

    if (typeof tab !== "undefined" && index !== 0) {
      try {
        tab[index].style.borderRight = "4px solid " + color;
        tabText[index].style.color = color;
      } catch (err) {}
    }
  }


  changeOrder() {
    // var tab = document.querySelectorAll("#chatframe .tabs-header-left .tabs li span .privateTab");
    // tab.forEach(function(userItem) {
    //         try {
    //           let parent = userItem.parentNode.parentNode;
    //           parent.classList.add("privateTabTop");
    //         } catch (err) {}
    // })   
  }

  hexToRGB(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  }

  checkTabColorIsHighlighted(index) {
    var tab = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner"
    );
    var tabText = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner .tabs-title"
    );

    if (typeof tab !== "undefined" && index !== 0) {
      try {
        if (
          this.hexToRGB(getGlobal().tabHighlightColor) ===
          tabText[index].style.color
        ) {
          return true;
        } else {
          return false;
        }
      } catch (err) {}
    }
    return false;
  }

  noColorByIndex(index) {
    var tab = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner"
    );
    var tabText = document.querySelectorAll(
      ".tabs-header-left .tabs li .tabs-inner .tabs-title"
    );

    if (typeof tab !== "undefined" && index !== 0) {
      try {
        tab[index].style.borderRight = "4px solid rgba(0,0,0,0.0)";
        tabText[index].style.color = "var(--app-tab-menu-text-color)";
      } catch (err) {}
    }
  }
}
