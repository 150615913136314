import { useThemeSwitcher } from 'react-css-theme-switcher';
import React, {setGlobal } from "reactn";
import shortid from "shortid";

import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { useEffect, useRef } from 'reactn';


const setStatusbarStyle = async (mode) => {
  const info = await Device.getInfo();
  if (info.platform !== 'web') {
    if (mode === 'dark') {
      StatusBar.setStyle({ style: Style.Dark });
    } else {
      StatusBar.setStyle({ style: Style.Light });
    }
  }
};

const rgbToHex = (rgbColor) => {
  var rgbValues = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (rgbValues) {
    var r = parseInt(rgbValues[1]);
    var g = parseInt(rgbValues[2]);
    var b = parseInt(rgbValues[3]);
    var hex = "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    return hex;
  }
  return "";
}

const componentToHex = (component) => {
  var hex = component.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

const ThemeSwitcher = (showText) => {
    const { switcher, themes, currentTheme } = useThemeSwitcher();
  
    const switchButtonChange = (e, value) => {
        e.preventDefault();
        switcher({ theme: value });  
        localStorage.setItem('theme', value);

        if (value.match(/dark/i)) {
          setGlobal({themeMode: 'dark'});
          setStatusbarStyle('dark');
        } else {
          setGlobal({themeMode: 'light'});
          setStatusbarStyle('light');
        }
    };


    const isMountedRef = useRef(false);
    useEffect(() => {
      if (isMountedRef.current) {
        // Az első renderelés után történő hatás
        var themeSwitcher = document.querySelector('.themeSwitcher');
        var activeButton = themeSwitcher.querySelector('.active');
        
        var computedStyle = window.getComputedStyle(activeButton);
        var color = computedStyle.getPropertyValue('background-color');
        var hexColor = rgbToHex(color);
    
        document.querySelector("meta[name='theme-color']").content = hexColor;
      } else {
        // Az első renderelés hatása
        isMountedRef.current = true;
      }
    });

  
    return (
      <div className="themeSwitcher"> 
         <span>Válassz témát:</span>

         {Object.keys(themes).map((theme, index) => {      
          
              console.log(index);
          
             if (theme !== currentTheme) {
                return (
                    <button className={ theme } key={shortid.generate()} onClick={(e) => switchButtonChange(e, theme)}></button>
                );
             } else {
                return (
                  <button className={`active ${theme}`} key={shortid.generate()} onClick={(e) => switchButtonChange(e, theme)}></button>
                );
             }
         })}    
      </div>
    );
  };
export default ThemeSwitcher