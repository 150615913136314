import React, { PureComponent } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import { SwitchButton } from 'rc-easyui';
import Popup from "reactjs-popup";
import roomController from '../classes/roomController';
import 'reactjs-popup/dist/index.css';

class SettingsModal extends PureComponent {

    constructor(props) {
        super(props);
        this.client = clientStorage.id;
        this.roomID = props.roomID;
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.changeSettings = this.changeSettings.bind(this);
        this.sendSettings = this.sendSettings.bind(this);

        this.state = {
            settings: this.global[this.roomID + 'Settings'],
        }

        this.roomController = new roomController(this.client);
        this.roomItem = this.roomController.getRoomItemByID(this.roomID);
    }

    handleOpenModal() {
        this.setState({ opened: true });
    }

    handleCloseModal() {
        this.setState({ opened: false });
    }

    componentDidUpdate(prevProps, prevState) {
        let settings = this.global[this.roomID + 'Settings']; 
        if (settings.id !== this.state.settings.id) {
            this.setState({
                settings: settings,
            });
        }
    }    

    sendSettings() {
        this.client.sendMessage('room_settings\x00' + this.roomID + '\x00'+ Number(this.state.settings.locked) + '\x00' + Number(this.state.settings.topicOpened) + '\x00' + this.state.settings.topic + '\x00'+ this.state.settings.bg + '\x00'+ this.state.settings.bgLight +'\x00' + Number(this.state.settings.autoConnect));
    }
    
    changeSettings(name, value) {
        let settings = Object.assign({}, this.state.settings);
        settings[name] = value;
        this.setState({
            settings: settings
        })
    }

    changeInputSettings(event, name) {
        let settings = Object.assign({}, this.state.settings);
        settings[name] =  event.target.value;
        this.setState({
            settings: settings
        })
    }

    render() {
        return (
            <div className="roomSettingsModal">
                <button className="settings" onClick={this.handleOpenModal} />
                <Popup open={this.state.opened} closeOnDocumentClick={true} className="roomSettingsModal" onClose={this.handleCloseModal}>
                    {close => (
                        <div className="modal">
                            <button className="close" onClick={close} />
                            <div className="header"> {this.roomItem.text} beállításai </div>
                            <Scrollbar noScrollX={true}>
                                <div className="content">
                                    <div className="field">
                                        <label>Automatikus belépés:</label>
                                        <div className="check">
                                            <SwitchButton onText="BE" offText="KI" value={(this.state.settings.autoConnect) ? true : false} onChange={(value) => this.changeSettings('autoConnect', value)}></SwitchButton>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label>A témát bárki módosíthatja:</label>
                                        <div className="check">
                                            {Number(this.state.settings.topicOpened) === 1 &&
                                                <SwitchButton onText="BE" offText="KI" value={(this.state.settings.topicOpened) ? true : false} onChange={(value) => this.changeSettings('topicOpened', value)}></SwitchButton>
                                            } 
                                            
                                            {Number(this.state.settings.topicOpened) === 0 &&
                                                <SwitchButton onText="BE" offText="KI" value={(this.state.settings.topicOpened) ? true : false} onChange={(value) => this.changeSettings('topicOpened', value)} disabled={(parseInt(this.state.settings.right)) ? "" : "disabled"}></SwitchButton>
                                            }
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label>A szoba témája:</label>
                                        <input type="text" placeholder="" value={this.state.settings.topic} onChange={(e) => this.changeInputSettings(e,'topic')} disabled={(this.state.settings.right) || (this.state.settings.topicOpened) ? "" : "disabled"} />
                                    </div>

                                    <div className="field">
                                        <label>A szoba sötét háttérképe:</label>
                                        <input type="text" placeholder="" value={this.state.settings.bg} onChange={(e) => this.changeInputSettings(e, 'bg')} disabled={(this.state.settings.right) ? "" : "disabled"} />
                                    </div>

                                    <div className="field">
                                        <label>A szoba világos háttérképe:</label>
                                        <input type="text" placeholder="" value={this.state.settings.bgLight} onChange={(e) => this.changeInputSettings(e, 'bgLight')} disabled={(this.state.settings.right) ? "" : "disabled"} />
                                    </div>

                                    <div className="field">
                                        <label>Szoba lezárása:</label>
                                        <div className="check">
                                            <SwitchButton onText="BE" offText="KI" value={(this.state.settings.locked) ? true : false} onChange={(value) => this.changeSettings('locked', value)} disabled={(parseInt(this.state.settings.right)) ? "" : "disabled"}></SwitchButton>
                                        </div>
                                    </div>

                                    <p className="submit field modal-footer">
                                        <button onClick={close} className="modalClose">Bezárás</button>
                                        <button onClick={() => this.sendSettings()}>Mentés</button>
                                    </p>
                                </div>
                            </Scrollbar>
                        </div>
                    )}
                </Popup>

            </div>
        );

    }

}


export default SettingsModal;