import React, { PureComponent } from 'reactn';
import { Form, FormField, ComboBox, SwitchButton, Label } from 'rc-easyui';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import ThemeSwitcher from "../components/ThemeSwitcher";

class Settings extends PureComponent {

	constructor(props) {
		super(props);
		this.client = clientStorage.id;
		this.setColor = this.setColor.bind(this);
		var stateString = null;
		var fontSizeString = null;

		if (this.global.user.status === 0) {
			stateString = 'Ne zavarj!';
		} else {
			stateString = 'Bárki zavarhat';
		}

		if (this.global.user.fontSize === 0 || this.global.user.fontSize === '' || this.global.user.fontSize === null) {
			fontSizeString = 'Automatikus';
		} else {
			fontSizeString = this.global.user.fontSize;
		}

		this.state = {
			user: this.global.user,
			actualState: stateString,
			actualFontsize: fontSizeString,
			rendered: false,
			states: [{
				value: 1,
				text: "Bárki zavarhat"
			},
			{
				value: 0,
				text: "Ne zavarj!"
			},
			],


			fontSizes: [
				{
					value: 0,
					text: 'Automatikus'
				},
				{
					value: 12,
					text: 12
				},
				{
					value: 13,
					text: 13
				},
				{
					value: 14,
					text: 14
				},
				{
					value: 15,
					text: 15
				},
				{
					value: 16,
					text: 16
				},
				{
					value: 17,
					text: 17
				},
				{
					value: 18,
					text: 18
				}
			]
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.global.selectedTab === 'settings') {
		  if (this.state.rendered === false) {
			this.setState({
			  rendered: true,
			});
			this.client.showLoading();
			setTimeout(() => this.client.hideLoading(), 300);			
		  } else {
			this.handleSubmit();
		  }
		}
	}

	setColor(event,color) {
		event.preventDefault();
		this.client.sendMessage("setcolor\x00" + color);
	}

	handleChange(name, value) {
		let user = Object.assign({}, this.state.user);
		user[name] = value;
		this.setState({
			user: user
		});		
	}

	componentDidMount() {
		var tabsDiv = document.querySelector(".icon-login");
		if (this.global.user.status === 0) {
			tabsDiv.classList.add("state-disturb");
			tabsDiv.classList.remove("state-normal");
		} else {
			tabsDiv.classList.remove("state-disturb");
			tabsDiv.classList.add("state-normal");
		}
	}


	shadeColor(color, percent) {
		var R = parseInt(color.substring(1,3),16);
		var G = parseInt(color.substring(3,5),16);
		var B = parseInt(color.substring(5,7),16);
	
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
	
		R = (R<255)?R:255;  
		G = (G<255)?G:255;  
		B = (B<255)?B:255;  
	
		var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
		var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
		var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
	
		return RR+GG+BB;
	  }

	handleSubmit() {
		var user = {};
		user = {
			autoMessage: this.state.user.autoMessage,
			highlight: this.state.user.highlight,
			status: this.state.user.status,
			beeping: this.state.user.beeping,
			joins: this.state.user.joins,
			highlightUsername: this.state.user.highlightUsername,
			modernView: this.state.user.modernView,
			coloredMessages: this.state.user.coloredMessages,
			userID: this.state.user.userID,
			profileImage: this.state.user.profileImage,
			vipColor: this.state.user.vipColor,
			urlPreview: this.state.user.urlPreview,
			smileys: this.state.user.smileys,
			roomListTree: this.state.user.roomListTree,

		};

		this.client.setGlobal({
			user: user,
		});

		localStorage.setItem('modernView', this.state.user.modernView);
		localStorage.setItem('coloredMessages', this.state.user.coloredMessages);
		localStorage.setItem('fontSize', this.state.user.fontSize);
		localStorage.setItem('urlPreview', this.state.user.urlPreview);
		localStorage.setItem('smileys', this.state.user.smileys);
		localStorage.setItem('roomListTree', this.state.user.roomListTree);

		if (this.state.user.fontSize === 0) {
			document.body.style.fontSize = `14px`;
		} else {
			document.body.style.fontSize = `${this.state.user.fontSize}px`;
		}

		this.client.sendMessage("settings\x00" + Number(this.state.user.beeping) + "\x00" + Number(false) + "\x00" + Number(this.state.user.joins) + "\x00" + Number(this.state.user.highlightUsername) + "\x00" + Number(false) + "\x00" + this.state.user.autoMessage + "\x00" + this.state.user.status + "\x00" + this.state.user.highlight + "\x000");
		//NotificationManager.info('A beállításokat elmentettük!', '', 2000);
	}

	render() {
		const { user, states, fontSizes } = this.state;

		if (this.state.rendered === true) {

			return (
				<div id="settings">
					<div className="chatModule">
	
								<div className="chatContainer" >
									<Scrollbar noScrollX={true}>



										<Form className="settingsForm"
											style={{ maxWidth: 750 }}
											model={user}
											labelWidth={200}
											labelAlign="left"
										>

											<h2 className="moduleTitle"><div className="moduleIcon icon-settings"></div> Beállítások</h2>

											<FormField name="status" label="Állapotod:">
												<ComboBox data={states} value={this.state.actualState} editable={false} onChange={(value) => this.handleChange("status", value)}></ComboBox>
											</FormField>

											{/* <FormField name="autoMessage" label="Automatikus válasz:">
												<TextBox value={user.autoMessage}></TextBox>
											</FormField> */}

											<FormField name="fontSize" label="Betűméret:">
												<ComboBox data={fontSizes} value={this.state.actualFontsize} editable={false} onChange={(value) => this.handleChange("fontSize", value)}></ComboBox>
											</FormField>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-3" style={{ width: 200 }}>Csipogás új üzenetnél:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-3" onText="BE" offText="KI" value={user.beeping} onChange={(value) => this.handleChange("beeping", value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-4" style={{ width: 200 }}>Be / kilépések kijelzése:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-4" onText="BE" offText="KI" value={user.joins} onChange={(value) => this.handleChange('joins', value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-8" style={{ width: 200 }}>Hivatkozások előnézete:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-8" onText="BE" offText="KI" value={user.urlPreview} onChange={(value) => this.handleChange('urlPreview', value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-9" style={{ width: 200 }}>Hangulatjelek megjelenítése:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-9" onText="BE" offText="KI" value={user.smileys} onChange={(value) => this.handleChange('smileys', value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-5" style={{ width: 200 }}>Jelzés a neved említésekor:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-5" onText="BE" offText="KI" value={user.highlightUsername} onChange={(value) => this.handleChange('highlightUsername', value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-6" style={{ width: 200 }}>Modern megjelenítés:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-6" onText="BE" offText="KI" value={user.modernView} onChange={(value) => this.handleChange('modernView', value)}></SwitchButton>
													</div>
												</div>
											</div>

											{ this.state.user.modernView === false &&
											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-7" style={{ width: 200 }}>Színes üzenetek:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-7" onText="BE" offText="KI" value={user.coloredMessages} onChange={(value) => this.handleChange('coloredMessages', value)}></SwitchButton>
													</div>
												</div>
											</div>
											}

											<div className="form-field f-column">
												<div className="f-full f-row f-vcenter">
													<Label htmlFor="form-field-inputid-8" style={{ width: 200 }}>Fa struktúrájú szoba lista:</Label>
													<div className="f-full">
														<SwitchButton inputId="form-field-inputid-8" onText="BE" offText="KI" value={user.roomListTree} onChange={(value) => this.handleChange('roomListTree', value)}></SwitchButton>
													</div>
												</div>
											</div>

											<div className="colors themes f-full f-row f-vcenter">
												<label className="textbox-label f-noshrink">Téma:</label>
												<ThemeSwitcher></ThemeSwitcher>
											</div>

											<div className="colors f-full f-row f-vcenter">
												<label className="textbox-label f-noshrink">Chates színed:</label>
												<div className="colorItems">
													{this.global.colors.map((item, index) => {

														let color;
														if (this.global.themeMode === 'dark') {
															color = {
																color: '#' + item,
																backgroundColor: '#' + item
															};
														} else {
															color = {
																color: "#" + this.shadeColor('#'+item,-25),
																backgroundColor: "#" + this.shadeColor('#'+item,-25)
															};
														}

														if (item === this.global.currentColor) {
															return <button name="currentColor" onClick={(e) => this.setColor(e, item)} className="current" key={index} style={color} />
														} else {
															return <button name="currentColor" onClick={(e) => this.setColor(e, item)} key={index} style={color} value="" />
														}
													})
													}
												</div>
											</div>
											{/* <FormField style={{ marginLeft: 200 }}>
												<LinkButton onClick={this.handleSubmit.bind(this)}>Mentés</LinkButton>
											</FormField> */}
										</Form>
									</Scrollbar>
								</div>	
					</div>
				</div>
			);
		} else {
			return <div>{this.global.selectedTab}</div>;
		}
	}
}

// <p>{JSON.stringify(user)}</p>
export default Settings;