import React, { PureComponent } from "reactn";
import Scrollbar from "react-scrollbars-custom";
import clientStorage from "../classes/clientStorage";
import { CheckBox, RadioButton } from "rc-easyui";
import InputMask from 'react-input-mask';
import PasswordMask from 'react-password-mask';
import Popup from "reactjs-popup";
import axios from "axios";
import smscode from "../images/smscode.svg";
import newemail from "../images/newemail.svg";
import newpassword from "../images/newpassword.svg";
import ReactCodeInput  from "react-code-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import 'reactjs-popup/dist/index.css';


class RegistrationModal extends PureComponent {
  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.handleOpenRegistration = this.handleOpenRegistration.bind(this);
    this.handleOpenNewSms = this.handleOpenNewSms.bind(this);
    this.handleOpenNewEmail = this.handleOpenNewEmail.bind(this);
    this.handleOpenRemember = this.handleOpenRemember.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.startRegistration = this.startRegistration.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.confirmSMSCode = this.confirmSMSCode.bind(this);
    this.confirmEmailCode = this.confirmEmailCode.bind(this);
    this.getNewSMSCode = this.getNewSMSCode.bind(this);
    this.getNewEmailCode = this.getNewEmailCode.bind(this);
    this.getNewRememberCode = this.getNewRememberCode.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);

    this.apiUrl = this.global.apiUrl;
    this.aszfUrl = this.global.aszfUrl;
    this.privacyUrl = this.global.privacyUrl;
    this.rulesUrl = this.global.rulesUrl;

    this.state = {
      username: "",
      name: "",
      gender: 1,
      email: "",
      birthday: "",
      telephone: "",
      telephoneAreas: [],
      telephoneArea: "",
      dataProtection: false,
      password: "",
      password2: "",
      aszf: false,
      genders: [
        {
          id: 1,
          name: "Férfi",
        },
        {
          id: 2,
          name: "Nő",
        },
        {
          id: 0,
          name: "Egyéb",
        },
      ],
      rules: false,
      smscode: "",
      emailcode: "",
      currentStep: 1,
      errors: [],
    };

    this.codeRef = React.createRef();
  }

  genderChange(value, checked) {
    if (checked) {
      this.setState({ gender: value });
    }
  }

  changeStep(step) {
    this.setState({ currentStep: step });
  }

  changeCheckboxes(checked, type) {
    if (type === "dataProtection") {
      this.setState({ dataProtection: checked });
    } else if (type === "aszf") {
      this.setState({ aszf: checked });
    } else if (type === "rules") {
      this.setState({ rules: checked });
    }
  }

  handleOpenRegistration() {
    this.getTelephoneAreas();
    this.setState({ opened: true, currentStep: 1, errors: [] });
  }

  handleOpenNewSms() {
    this.setState({ opened: true, currentStep: 4 });
  }

  handleOpenNewEmail() {
    this.setState({ opened: true, currentStep: 5 });
  }

  handleOpenRemember() {
    this.setState({ opened: true, currentStep: 6 });
  }

  handleCloseModal() {
    this.setState({ opened: false });
  }

  changeInputSettings(event, name) {
    if (name === "username") {
      this.setState({ username: event.target.value });
    } else if (name === "password") {
      this.setState({ password: event.target.value });
    } else if (name === "password2") {
      this.setState({ password2: event.target.value });
    } else if (name === "email") {
      this.setState({ email: event.target.value });
    } else if (name === "birthday") {
      this.setState({ birthday: event.target.value });
    } else if (name === "telephone") {
      this.setState({ telephone: event.target.value });
    } else if (name === "telephoneArea") {
      this.setState({ telephoneArea: event.target.value });
    } else if (name === "name") {
      this.setState({ name: event.target.value });
    } else if (name === "smscode") {
      this.setState({ smscode: event });
    } else if (name === "emailcode") {
      console.log(event);
      this.setState({ emailcode: event });
    }
  }

  startRegistration(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append("add", true);
    fd.append("username", this.state.username);
    fd.append("name", this.state.name);
    fd.append("password", this.state.password);
    fd.append("password2", this.state.password2);
    fd.append("gender", this.state.gender);
    fd.append("email", this.state.email);
    fd.append("birthday", this.state.birthday);
    fd.append("telephone", this.state.telephone);
    fd.append("telephoneArea", this.state.telephoneArea);
    fd.append("dataProtection", Number(this.state.dataProtection));
    fd.append("aszf", Number(this.state.aszf));
    fd.append("rules", Number(this.state.rules));

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });

    axios.post(this.apiUrl + "/registration", fd, { headers }).then((res) => {
      if (res.data.registration === "error") {
        this.setState({ errors: res.data.errors });
      } else if (res.data.registration === "ok") {
        this.setState({ currentStep: 2 });
      }
    });
  }

  getTelephoneAreas() {
    let fd = new FormData();
    fd.append("getTelephoneAreas", true);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios
      .post(this.apiUrl + "/getTelephoneAreas", fd, { headers })
      .then((res) => {
        this.setState({
          telephoneAreas: res.data.telephoneAreas,
          telephoneArea: res.data.telephoneAreas[0],
        });
      });
  }

  confirmSMSCode(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append("confirmCode", true);
    fd.append("email", this.state.email);
    fd.append("code", this.state.smscode);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });

    axios.post(this.apiUrl + "/confirmCode", fd, { headers }).then((res) => {
      if (res.data.confirmCode === "ok") {
        this.clearPin();
        this.client.sendAlert(
          "Sikeres regisztráció!",
          "Köszöntünk a felhasználóink között! A fiókod létrejött, már csak be kell jelentkezned.",
          "success"
        );
        this.setState({
          currentStep: 1,
          opened: false,
          username: "",
          name: "",
          gender: "",
          email: "",
          birthday: "",
          telephone: "",
          dataProtection: "",
          password: "",
          password2: "",
          smscode: "",
          emailcode: "",
          aszf: false,
        });
      } else if (res.data.confirmCode === "bad syntax") {
        this.setState({ errors: res.data.errors });
      } else {
        this.setState({ errors: res.data.errors });
      }
    });
  }

  confirmEmailCode(event) {
    event.preventDefault();
    let fd = new FormData();
    fd.append("confirmCode", true);
    fd.append("email", this.state.email);
    fd.append("code", this.state.emailcode);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });

    axios
      .post(this.apiUrl + "/confirmEmailCode", fd, { headers })
      .then((res) => {
        if (res.data.confirmCode === "ok") {
          this.setState({ emailcode: "" });
          this.clearPin();
          this.client.sendAlert(
            "Sikeres hitelesítés!",
            "Már csak a telefonszámod kell hitelesíteni és elkészül a fiókod.",
            "info"
          );
          this.setState({ currentStep: 3 });
        } else if (res.data.confirmCode === "bad syntax") {
          this.setState({ errors: res.data.errors });
        } else {
          this.setState({ errors: res.data.errors });
        }
      });
  }

  getNewSMSCode(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append("getNewCode", true);
    fd.append("email", this.state.email);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });

    axios.post(this.apiUrl + "/getNewCode", fd, { headers }).then((res) => {
      if (res.data.getNewCode === "ok") {
        this.setState({ smscode: "" });
        this.client.sendAlert(
          "Küldtünk egy új kódot!",
          "Ellenőrizd a telefonod, lehet már meg is érkezett az aktiváló kód!",
          "info"
        );
        this.setState({ currentStep: 3 });
      } else if (res.data.getNewCode === "bad syntax") {
        this.setState({ errors: res.data.errors });
      } else {
        this.setState({ errors: res.data.errors });
      }
    });
  }

  getNewEmailCode(event) {
    event.preventDefault();
    let fd = new FormData();
    fd.append("getNewCode", true);
    fd.append("email", this.state.email);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios
      .post(this.apiUrl + "/getNewEmailCode", fd, { headers })
      .then((res) => {
        if (res.data.getNewEmailCode === "ok") {
          this.setState({ emailcode: "" });
          this.client.sendAlert(
            "Küldtünk egy új kódot!",
            "Ellenőrizd az e-mail fiókod, lehet már meg is érkezett az aktiváló kód!",
            "info"
          );
          this.setState({ currentStep: 2 });
        } else if (res.data.getNewEmailCode === "bad syntax") {
          this.setState({ errors: res.data.errors });
        } else {
          this.setState({ errors: res.data.errors });
        }
      });
  }

  getNewRememberCode(event) {
    event.preventDefault();
    let fd = new FormData();
    fd.append("getNewCode", true);
    fd.append("email", this.state.email);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios
      .post(this.apiUrl + "/getNewRememberCode", fd, { headers })
      .then((res) => {
        console.log(res.data);
        if (res.data.getNewRememberCode === "ok") {
          this.setState({ emailcode: "" });
          this.client.sendAlert(
            "Küldtünk egy kódot a jelszavad megváltoztatásához!",
            "Ellenőrizd az e-mail fiókod, lehet már meg is érkezett a kód!",
            "info"
          );
          this.setState({ currentStep: 7 });
        } else if (res.data.getNewRememberCode === "bad syntax") {
          this.setState({ errors: res.data.errors });
        } else {
          this.setState({ errors: res.data.errors });
        }
      });
  }

  setNewPassword(event) {
    event.preventDefault();

    let fd = new FormData();
    fd.append("setNewPassword", true);
    fd.append("email", this.state.email);
    fd.append("code", this.state.emailcode);
    fd.append("password", this.state.password);
    fd.append("password2", this.state.password2);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    this.setState({ errors: {} });
    axios.post(this.apiUrl + "/setNewPassword", fd, { headers }).then((res) => {
      if (res.data.setNewPassword === "ok") {
        this.setState({ emailcode: "", email: "", opened: false });
        this.client.sendAlert(
          "Sikeresen megváltoztattad a jelszavad!",
          "Amennyiben nem tudnál ennek ellenére belépni a chatre, írj nekünk és segítünk!",
          "success"
        );
      } else if (res.data.setNewPassword === "bad syntax") {
        this.setState({ errors: res.data.errors });
      } else {
        this.setState({ errors: res.data.errors });
      }
    });
  }

  clearPin() {
    if(this.codeRef.current.textInput[0]) this.codeRef.current.textInput[0].focus()
    this.codeRef.current.state.input[0] = ""
    this.codeRef.current.state.input[1] = ""
    this.codeRef.current.state.input[2] = ""
    this.codeRef.current.state.input[3] = ""
    this.codeRef.current.state.input[4] = ""
    this.codeRef.current.state.input[5] = ""
  }

  render() {
    const buttons = (
      <div className="buttons">
        <p className="remember">
          <button onClick={this.handleOpenRemember}>
            Elfelejtetted a jelszavad?
          </button>
        </p>
        <button className="registration" onClick={this.handleOpenRegistration}>
          Regisztráció
        </button>
        <p className="newemail">
          <button onClick={this.handleOpenNewEmail}>
            Nem kaptad meg a hitelesítő e-mailt?
          </button>
        </p>
        <p className="newsmscode">
          <button onClick={this.handleOpenNewSms}>
            Nem kaptad meg a hitelesítő SMS-t?
          </button>
        </p>
      </div>
    );

    if (this.state.currentStep === 1) {

      const showPassword = <FontAwesomeIcon icon={faEye} />
      const hidePassword = <FontAwesomeIcon icon={faEyeSlash} />

      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Regisztráció </div>

                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.startRegistration}>
                    <div className="content">
                      <div className="field">
                        <label>Felhasználónév:</label>
                        <input
                          type="text"
                          value={this.state.username}
                          className={
                            typeof this.state.errors.username !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          name="username"
                          onChange={(e) =>
                            this.changeInputSettings(e, "username")
                          }
                          placeholder="pl: peter123"
                        />
                      </div>

                      {typeof this.state.errors.username !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.username}
                        </div>
                      )}

                      <div className="field">
                        <label>Neved:</label>
                        <input
                          type="text"
                          value={this.state.name}
                          className={
                            typeof this.state.errors.name !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          name="name"
                          onChange={(e) => this.changeInputSettings(e, "name")}
                          placeholder="pl: Teszt Péter"
                        />
                      </div>

                      {typeof this.state.errors.name !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.name}
                        </div>
                      )}

                      <div className="field">
                        <label>Jelszavad:</label>

                        <PasswordMask
                          name="password"
                          value={this.state.password}
                          className="maskedPassword"
                          id="password1"
                          inputClassName={
                            typeof this.state.errors.password !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) =>
                            this.changeInputSettings(e, "password")
                          }
                          placeholder=""
                          buttonClassName="maskedPasswordButton"
                          showButtonContent={showPassword}
                          hideButtonContent={hidePassword}
                        />

                      </div>

                      {typeof this.state.errors.password !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password}
                        </div>
                      )}

                      <div className="field">
                        <label>Jelszavad újra:</label>
   
                        <PasswordMask
                          name="password2"
                          id="password2"
                          value={this.state.password2}
                          className="maskedPassword"
                          inputClassName={
                            typeof this.state.errors.password !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) =>
                            this.changeInputSettings(e, "password2")
                          }
                          placeholder=""
                          buttonClassName="maskedPasswordButton"
                          showButtonContent={showPassword}
                          hideButtonContent={hidePassword}
                        />

                      </div>

                      {typeof this.state.errors.password2 !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password2}
                        </div>
                      )}

                      <div className="field">
                        <label>Nemed:</label>
                        <div className="formItem check">
                          {this.state.genders.map((gender) => {
                            return (
                              <div key={gender.name}>
                                <div className="left">
                                  <RadioButton
                                    inputId={gender.name}
                                    value={gender.id}
                                    groupValue={this.state.gender}
                                    className={
                                      typeof this.state.errors.gender !==
                                      "undefined"
                                        ? "errorBorder"
                                        : ""
                                    }
                                    onChange={(checked) =>
                                      this.genderChange(gender.id, checked)
                                    }
                                  />
                                </div>
                                <div className="right">
                                  <label
                                    htmlFor={gender.name}
                                    className={
                                      typeof this.state.errors.gender !==
                                      "undefined"
                                        ? "errorColor"
                                        : ""
                                    }
                                  >
                                    {gender.name}
                                  </label>
                                </div>
                                <div className="separator"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {typeof this.state.errors.gender !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.gender}
                        </div>
                      )}

                      <div className="field">
                        <label>E-mail címed:</label>
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          className={
                            typeof this.state.errors.email !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) => this.changeInputSettings(e, "email")}
                          placeholder="pl: teszt.peter@gmail.com"
                        />
                      </div>

                      {typeof this.state.errors.email !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.email}
                        </div>
                      )}

                      <div className="field">
                        <label>Születésnapod:</label>
                        <div className="formItem check">
               
                          <InputMask
                           name="birthday"
                           value={this.state.birthday}
                           className={
                             typeof this.state.errors.birthday !== "undefined"
                               ? "errorBorder masked"
                               : "masked"
                           }
                           onChange={(e) =>
                             this.changeInputSettings(e, "birthday")
                           }                          
                           mask="9999-99-99" 
                           maskChar="_" 
                           placeholder="____-__-__"
                           />

                        </div>
                      </div>

                      {typeof this.state.errors.birthday !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.birthday}
                        </div>
                      )}

                      <div className="field">
                        <label>Telefonszámod:</label>
                        <div className="formItem check telephone">
                          <select
                            value={this.state.telephoneArea}
                            className={
                              typeof this.state.errors.telephoneArea !==
                              "undefined"
                                ? "errorBorder"
                                : ""
                            }
                            onChange={(e) =>
                              this.changeInputSettings(e, "telephoneArea")
                            }
                          >
                            {this.state.telephoneAreas.map((item, index) => {
                              return <option value={item} key={item}>{item}</option>;
                            })}
                          </select>

                          <InputMask
                           name="telephone"
                           value={this.state.telephone}
                           className={
                             typeof this.state.errors.telephone !== "undefined"
                               ? "errorBorder masked"
                               : "masked"
                           }
                           onChange={(e) =>
                             this.changeInputSettings(e, "telephone")
                           }                          
                           mask="9999999999" 
                           maskChar="" 
                           placeholder=""
                           /> 

                          <div className="priority">
                            A megadott telefonszámra hitelesítő kódot fogunk
                            küldeni a regisztráció véglegesítéséhez. <br />
                            Nem adjuk ki a telefonszámod harmadik fél részére és
                            nem küldünk rá semmilyen reklámanyagot a jövőben!
                          </div>
                        </div>
                      </div>

                      {typeof this.state.errors.telephone !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.telephone}
                        </div>
                      )}

                      {typeof this.state.errors.telephoneArea !==
                        "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.telephoneArea}{" "}
                        </div>
                      )}

                      <div className="field">
                        <div className="formItem check">
                          <div className="left">
                            <CheckBox
                              inputId="privacy"
                              name="dataProtection"
                              checked={this.state.dataProtection}
                              onChange={(checked) =>
                                this.changeCheckboxes(checked, "dataProtection")
                              }
                            ></CheckBox>
                          </div>
                          <div className="right">
                            <label
                              htmlFor="privacy"
                              className={
                                typeof this.state.errors.dataProtection !== "undefined"
                                  ? "errorColor"
                                  : ""
                              }
                            >
                              Elolvastam és elfogadom az{" "}
                              <a
                                href={this.privacyUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                adatvédelmi szabályzatot
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <div className="formItem check">
                          <div className="left">
                            <CheckBox
                              inputId="aszf"
                              name="aszf"
                              checked={this.state.aszf}
                              onChange={(checked) =>
                                this.changeCheckboxes(checked, "aszf")
                              }
                            ></CheckBox>
                          </div>
                          <div className="right">
                            <label
                              htmlFor="aszf"
                              className={
                                typeof this.state.errors.aszf !== "undefined"
                                  ? "errorColor"
                                  : ""
                              }
                            >
                              Elolvastam és elfogadom az{" "}
                              <a
                                href={this.aszfUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Általános Szerződési Feltételeket.
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <div className="formItem check">
                          <div className="left">
                            <CheckBox
                              inputId="rules"
                              name="rules"
                              checked={this.state.rules}
                              onChange={(checked) =>
                                this.changeCheckboxes(checked, "rules")
                              }
                            ></CheckBox>
                          </div>
                          <div className="right">
                            <label
                              htmlFor="rules"
                              className={
                                typeof this.state.errors.rules !== "undefined"
                                  ? "errorColor"
                                  : ""
                              }
                            >
                              Elolvastam és elfogadom a{" "}
                              <a
                                href={this.rulesUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                TeamChat szabályzatát
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>

                      <p className="submit field">
                        <button type="submit" className="active">
                          Tovább &#10132;
                        </button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 2) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> E-mail cím hitelesítése </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.confirmEmailCode}>
                    <div className="content">
                      <div className="smscode-image">
                        <img
                          src={newemail}
                          alt="E-mail cím hitelesítése"
                          title="E-mail cím hitelesítése"
                        />
                      </div>
                      <p className="smscode-instruction">
                        Küldtünk neked egy hitelesítő kódot a megadott e-mail
                        címedre.
                        <br />
                        Kérjük írd be a kódot az e-mail címed hitelesítéséhez!
                      </p>
                      <div className="field">
                        <div className="smscode">
                          <ReactCodeInput id="emailcode-2" type='number' fields={6} ref={this.codeRef}
                             value={this.state.emailcode}                          
                             onChange={(e) =>
                              this.changeInputSettings(e, "emailcode")
                            }
                            name="emailcode"                          
                          />
                        </div>
                      </div>
                      {typeof this.state.errors.emailcode !== "undefined" && (
                        <div className="fieldError" style={{float: "none"}}>
                          {this.state.errors.emailcode}
                        </div>
                      )}
                      <p className="smscode-submit">
                        <button type="submit" className="active">
                          Tovább &#10132;
                        </button>
                      </p>
                      <button
                        type="submit"
                        className="newsmscode"
                        onClick={() => this.getNewEmailCode()}
                      >
                        Új kódot kérek
                      </button>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 3) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Telefonszám hitelesítése </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.confirmSMSCode}>
                    <div className="content">
                      <div className="smscode-image">
                        <img src={smscode} alt="SMS" title="SMS" />
                      </div>
                      <p className="smscode-instruction">
                        Küldtünk neked egy aktiváló kódot <strong>SMS</strong>
                        -ben a telefonszámodra.
                        <br />
                        Kérjük írd be a regisztrációd aktiválásához!
                      </p>
                      <div className="field">
                        <div className="smscode">
                          <ReactCodeInput id="smscode-2" type='number' fields={6} ref={this.codeRef}
                             value={this.state.smscode}
                             onChange={(e) =>
                              this.changeInputSettings(e, "smscode")
                            }
                            name="smscode"                          
                          />
                        </div>
                      </div>
                      {typeof this.state.errors.smscode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.smscode}
                        </div>
                      )}
                      <p className="smscode-submit">
                        <button type="submit" className="active">
                          Tovább &#10132;
                        </button>
                      </p>

                      <button
                        type="button"
                        className="newsmscode"
                        onClick={() => this.getNewSMSCode()}
                      >
                        Új kódot kérek
                      </button>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 4) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Telefonszám hitelesítése </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.getNewSMSCode}>
                    <div className="content">
                      <div className="smscode-image">
                        <img src={smscode} alt="SMS" title="SMS" />
                      </div>
                      <p
                        className="smscode-instruction"
                        style={{ marginBottom: 30 }}
                      >
                        <strong>Fontos:</strong> Utólagos aktiváló{" "}
                        <strong>SMS</strong>-t csak sikeres e-mail hitelesítés
                        után lehet kérni.
                      </p>
                      <div className="field">
                        <label>E-mail címed:</label>
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          className={
                            typeof this.state.errors.smscode !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) => this.changeInputSettings(e, "email")}
                          placeholder="pl: teszt.peter@gmail.com"
                        />
                      </div>

                      {typeof this.state.errors.smscode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.smscode}
                        </div>
                      )}

                      <p className="smscode-submit">
                        <button type="button" onClick={this.handleCloseModal}>
                          Bezárás
                        </button>
                        <button type="submit">Mehet</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 5) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> E-mail cím hitelesítése </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.getNewEmailCode}>
                    <div className="content">
                      <div className="smscode-image">
                        <img src={newemail} alt="SMS" title="SMS" />
                      </div>
                      <p
                        className="smscode-instruction"
                        style={{ marginBottom: 30 }}
                      >
                        Amennyiben nem érkezett meg regisztrációkor <br />a
                        visszaigazoló <strong>E-MAIL</strong>, itt tudsz újat
                        kérni!
                      </p>

                      <div className="field">
                        <label>E-mail címed:</label>
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          className={
                            typeof this.state.errors.emailcode !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) => this.changeInputSettings(e, "email")}
                          placeholder="pl: teszt.peter@gmail.com"
                        />
                      </div>

                      {typeof this.state.errors.emailcode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.emailcode}
                        </div>
                      )}

                      <p className="smscode-submit">
                        <button type="button" onClick={this.handleCloseModal}>
                          Bezárás
                        </button>
                        <button type="submit">Mehet</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 6) {
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Elfelejtett jelszó </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.getNewRememberCode}>
                    <div className="content">
                      <div className="newpass-image">
                        <img src={newpassword} alt="SMS" title="SMS" />
                      </div>
                      <p
                        className="smscode-instruction"
                        style={{ marginBottom: 30 }}
                      >
                        Küldünk egy kódot a megadott e-mail címedre, amit ha
                        beírsz megváltoztathatod a jelszavad.
                      </p>

                      <div className="field">
                        <label>E-mail címed:</label>
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          className={
                            typeof this.state.errors.emailcode !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) => this.changeInputSettings(e, "email")}
                          placeholder="pl: teszt.peter@gmail.com"
                        />
                      </div>

                      {typeof this.state.errors.emailcode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.emailcode}
                        </div>
                      )}

                      <p className="smscode-submit">
                        <button type="button" onClick={this.handleCloseModal}>
                          Bezárás
                        </button>
                        <button type="submit">Mehet</button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    } else if (this.state.currentStep === 7) {
      const showPassword = <FontAwesomeIcon icon={faEye} />
      const hidePassword = <FontAwesomeIcon icon={faEyeSlash} />
      return (
        <div>
          {buttons}
          <Popup
            open={this.state.opened}
            closeOnDocumentClick={false}
            onClose={this.handleCloseModal}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close} />
                <div className="header"> Jelszóemlékeztető </div>
                <Scrollbar noScrollX={true}>
                  <form onSubmit={this.setNewPassword}>
                    <div className="content">
                      <div className="newpass-image">
                        <img
                          src={newpassword}
                          alt="E-mail cím hitelesítése"
                          title="E-mail cím hitelesítése"
                        />
                      </div>
                      <p className="smscode-instruction">
                        Küldtünk neked egy hitelesítő kódot a megadott e-mail
                        címedre.
                        <br />
                        Kérjük írd be a kódot majd add meg az új jelszavad!
                      </p>
                      <div className="field">
                        <div className="smscode">
                          <ReactCodeInput type='number' fields={6} ref={this.codeRef}
                             value={this.state.emailcode}
                             onChange={(e) =>
                              this.changeInputSettings(e, "emailcode")
                            }
                            name="emailcode"                          
                          />
                        </div>
                      </div>
                      {typeof this.state.errors.emailcode !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.emailcode}
                        </div>
                      )}

                      <div className="field" style={{ paddingTop: 20 }}>
                        <label>Új jelszavad:</label>
                        <PasswordMask
                          name="password"
                          value={this.state.password}
                          className="maskedPassword"
                          id="password1"
                          inputClassName={
                            typeof this.state.errors.password !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) =>
                            this.changeInputSettings(e, "password")
                          }
                          placeholder=""
                          buttonClassName="maskedPasswordButton"
                          showButtonContent={showPassword}
                          hideButtonContent={hidePassword}
                        />
                      </div>

                      {typeof this.state.errors.password !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password}
                        </div>
                      )}

                      <div className="field">
                        <label>Új jelszavad újra:</label>
                        <PasswordMask
                          name="password2"
                          id="password2"
                          value={this.state.password2}
                          className="maskedPassword"
                          inputClassName={
                            typeof this.state.errors.password !== "undefined"
                              ? "errorBorder"
                              : ""
                          }
                          onChange={(e) =>
                            this.changeInputSettings(e, "password2")
                          }
                          placeholder=""
                          buttonClassName="maskedPasswordButton"
                          showButtonContent={showPassword}
                          hideButtonContent={hidePassword}
                        />
                      </div>

                      {typeof this.state.errors.password2 !== "undefined" && (
                        <div className="fieldError">
                          {this.state.errors.password2}
                        </div>
                      )}

                      <p className="smscode-submit">
                        <button type="submit" className="active">
                          Tovább &#10132;
                        </button>
                      </p>
                    </div>
                  </form>
                </Scrollbar>
              </div>
            )}
          </Popup>
        </div>
      );
    }
  }
}

export default RegistrationModal;
