import {React, setGlobal, getGlobal} from 'reactn';
import ReactDOM from 'react-dom';
import App from './App';

//import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import useVH from 'react-viewport-height';

import './css/main/index.css';

const themes = {
  dark: '/css/dark/dark.css?v=10',
  darkgrey: '/css/darkgrey/darkgrey.css?v=11',
  // darkdsc: '/css/darkdsc/darkdsc.css?v=10',
  // lightdsc: '/css/lightdsc/lightdsc.css?v=10', 
};


setGlobal({
  //system
  user: [],
  moderator: false,
  rooms: [],
  freezed: false,
  freezedRooms: [],
  freezedPrivates: [],
  topics: [],
  users: [],
  ignores: [],
  favorites: [],
  notifications: [],
  customStatusbarText: "",
  onlineUsers: 0,
  actualFontSize: "automatikus",
  sid: 0,
  reconnect: false,
  username: "",
  serverTime: "",
  selectedTab: null,
  FCMToken: null,
  themeMode: 'dark',
  defaultTheme: 'dark',

  //profileModal
  profileModalOpened: false,
  profileModalOpenedUser: "",

  //moderatorModal
  moderatorModalOpened: false,
  moderatorModalOpenedUser: "",
  moderatorModalOpenedRoom: "",

  //options
  roomMessageLimit: 2000000,
  showMobileTabs: true,
  showRoomUserlist: true,

  //static
  appName: "TeamChat 0.1.8",
  email: "support@teamchat.hu",
  version: "%teamchat-build-version%",
  profileImageUrl: "https://teamchat.hu/gallery/user-photos",
  apiUrl: "https://api.teamchat.hu",
  aszfUrl: "https://teamchat.hu/aszf",
  privacyUrl: "https://teamchat.hu/adatvedelem",
  rulesUrl: "https://teamchat.hu/szabalyzat",
  proxyUrl: "https://proxy.teamchat.hu/proxy",

  // colors
  customStatusbarColor: "#ffffff",
  tabNewMessageColor: "var(--app-tab-new-message-color)",
  tabNewPrivateMessageColor: "var(--app-tab-new-private-message-color)",
  tabNewNotificationMessageColor: "var(--app-tab-new-notification-color)",
  tabHighlightColor: "var(--app-tab-highlight-color)",
  favoriteUserExitColor: "#c54343",
  favoriteUserJoinColor: "#77e75b",
  userJoinColor: "90929B",
  userPartColor: "60636F",
  userExitColor: "484C5A",
  currentColor: "FFFFFF",
  colors: [
    "FFFFFF",
    "FFF1D9",
    "FFEAC2",
    "E4C792",
    "F5A065",
    "EE8C60",
    "FF6700",
    "C83214",
    "FF8C1A",
    "FFA200",
    "FFD000",
    "f8fd2b",
    "F2FB00",
    "C4E900",
    "A5DD00",
    "7ECE00",
    "00FF00",
    "37B200",
    "00AE76",
    "7BAF71",
    "48F2AA",
    "B5FDA4",
    "d9ffd9",
    "01FDFF",
    "34C8FF",
    "7288FF",
    "939fff",
    "BFBFFF",
    "9DB1D9",
    "D3D3D3",
    "C1C1C1",
    "8FC1B6",
    "40C1BD",
    "FCA9F1",
    "FF72E3",
    "ff57b3",
    "cc00ff",
    "ff00ff",
    "cc0099",
    "9966cc",
    "876D92",
    "FF9F9F",
    "b8ba71",
    "B49162",
    "0080B0",
    "778899",
    "BC8F8F",
    "FF4500",
    "EE82EE",
    "8A2BE2",
    "DC143C",
    "00BFFF",
    "00A0A0",
    "0040FF",
    "7FFF00",
    "ADFF2F",
    "CDFF3C",
    "ccff00",
    "99ff00",
    "4381AF",
  ],
  // end colors
});

var themeSaved = localStorage.getItem("theme");
if (themeSaved !== "" && themeSaved !== null) {
  setGlobal({defaultTheme: localStorage.getItem("theme")});
} else {
  setGlobal({defaultTheme: 'dark'});
}

if (getGlobal().defaultTheme !== 'dark') {

  if (getGlobal().defaultTheme.match(/dark/i)) {
    setGlobal({themeMode: 'dark'});
  } else {
    setGlobal({themeMode: 'light'});
  }
}

const TeamChat = () => {
  useVH();

  return  <App/>
};

  ReactDOM.render(
    <ThemeSwitcherProvider defaultTheme={getGlobal().defaultTheme} themeMap={themes}>
       <TeamChat />
    </ThemeSwitcherProvider>,
  document.getElementById('root')
  )



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
