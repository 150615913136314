import React, { PureComponent, getGlobal, setGlobal } from "reactn";
import Twemoji from "react-twemoji";
import clientStorage from "../classes/clientStorage";
import Scrollbar from "react-scrollbars-custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { isMobileOnly } from "react-device-detect";
// import Message from "./Message";
import { Circle } from 'react-preloaders';

import Linkify from "react-linkify";
import { toArray } from "react-emoji-render";
import shortid from "shortid";
import { ReactTinyLink } from "react-tiny-link";
import axios from 'axios';

import LightboxWrapper from './LightboxWrapper';

class RoomMessages extends PureComponent {
  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.roomID = this.props.roomID;
    this.mode = this.props.mode;
    this.state = {
      data: [],
      scrollDown: true,
      scrollTop: false,
      noScroll: false,
      loaded:false,
      rendered:false,
      messageLoader: false,
      hideHistoryLoadButton: false
    };

    this.onScroll = this.onScroll.bind(this);
    this.scrollUpdate = this.scrollUpdate.bind(this);
    this.scrollBottom = this.scrollBottom.bind(this);
    this.componentDecorator = this.componentDecorator.bind(this);
    this.checkLastUrl = this.checkLastUrl.bind(this);
    this.loadPreviousMessages = this.loadPreviousMessages.bind(this);
    this.handleLightboxStateChange = this.handleLightboxStateChange.bind(this);


    if (isMobileOnly) {
      this.maxMessageLimit = 30;
      this.normalMessageLimit = 30;
    } else {      
      this.maxMessageLimit = 80;
      this.normalMessageLimit = 80;
    }

    this.scrollDown = true;
    this.scrollTop = false;
    this.scrollBar = React.createRef();
    this.lastScrollPosition = 0;

    this.resultCache = [];
    this.modernView = getGlobal().user.modernView;
    this.themeMode = getGlobal().themeMode;
    this.coloredMessages = getGlobal().user.coloredMessages;
    this.themeMode = getGlobal().themeMode;
    this.modernView = getGlobal().user.modernView; 
    this.lastScrollHeight = 0;
    this.currentPage = 1;
    this.lastHistoryFromID = 0;    
  }

  handleLightboxStateChange(isOpen) {
    this.setState({ closeOnDocumentClick: !isOpen });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.global.selectedTab === this.roomID) {
      if (this.state.rendered === false) {
        this.getHistory(); 
        this.setState({
          rendered: true,
          loaded: true
        });
      }
    } else {
      this.scrollDown = true;
      this.currentPage = 1;
    }

    if (this.global.themeMode !== this.themeMode) {
      this.resultCache = [];
      this.setState({
        rendered: false,
      });
      this.themeMode = this.global.themeMode;
    }

    if (this.global.user.modernView !== this.modernView) {
      this.resultCache = [];
      this.setState({
        rendered: false,
      });
      this.modernView = this.global.user.modernView;
    }

    if (this.global.user.coloredMessages !== this.coloredMessages) {
      this.resultCache = [];
      this.setState({
        rendered: false,
      });
      this.coloredMessages = this.global.user.coloredMessages;
    }

    if (this.global.freezed === true) {
      this.setState({
        freezed: true,
      });
    }

    if (this.global.freezed === false) {
      if (this.state.freezed === true) {
        this.getHistory();
        this.setState({
          freezed: false,
        });
      }
    }

    if (this.scrollDown === true) {
      this.scrollBottom();
    }

  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.scrollBottom();
  }

  updateDimensions = () => {
    this.scrollBottom();
  };

  getFirstMessageID() {
    let storeMessages = this.global[this.roomID + "Messages"];
    if (storeMessages[0]) {
      let firstMessage = storeMessages[0];
      return firstMessage.id; 
    } else {
      return -1;
    }
  }

  getHistory(fromID=0) {
    let fd = new FormData();
    let history;
    let command;
    let progress = true;

    if (!this.state.rendered && !this.state.loaded) {
      this.client.showLoading();
    }

    if (this.mode === 'private') {
      fd.append("getPrivateHistory", true);
      command = 'getPrivateHistory';
    } else {
      fd.append("getRoomHistory", true);
      command = 'getRoomHistory';
    }
  
    if (fromID !== 0) {
      fd.append("limit", 20);
      fd.append("fromID", fromID);
      this.currentPage = this.currentPage+1;

      if (this.lastHistoryFromID <= fromID && this.lastHistoryFromID !== 0) {
        progress = false;
      } else {

        this.lastHistoryFromID = fromID;
      }
    } else {
      fd.append("limit", this.maxMessageLimit);
    }

    if (progress) {
      fd.append("sid", this.global.sid);
      fd.append("roomID", this.roomID);
      this.setState({ messageLoader: true });
      axios.post(this.global.apiUrl + "/"+command, fd).then((res) => {
        if (res.data.getRoomHistory === "ok" || res.data.getPrivateHistory === "ok") {
          history = res.data.history;

          if (fromID === 0) {
            setGlobal({
              [this.roomID + "Messages"]: history,
            });
          } else {
            if (history.length > 0) {
              let storeMessages = this.global[this.roomID + "Messages"];
              setGlobal({
                [this.roomID + "Messages"]: history.concat(storeMessages)
              });
            }            
          }  

          if (history.length === 0) {
            setTimeout(() => this.setState({ messageLoader: false, hideHistoryLoadButton: true }), 300);
          } else {
            setTimeout(() => this.setState({ messageLoader: false }), 300);
          }
        } else {
          setTimeout(() => this.setState({ messageLoader: false }), 300);
        }       
        setTimeout(() =>  this.client.hideLoading(), 300);        
      });      
    }    
  }

  openProfile(e, username) {
    setGlobal({
      profileModalOpened: true,
      profileModalOpenedUser: username,
    });
  }  

  handleDoubleClickItem(name) {
    this.client.sendMessage("private_join\x00" + name);
  }

  onScroll(scrollvalues, prevscrollvalues) {
    var current = parseInt(scrollvalues.clientHeight + scrollvalues.scrollTop);
    var difference = scrollvalues.contentScrollHeight - current;

    if (difference <= 50) {
      this.scrollDown = true;
    } else {
      this.scrollDown = false;
    }
  }


  scrollUpdate(scrollvalues, prevscrollvalues) {
    const { scrollTop, clientHeight, contentScrollHeight } = scrollvalues;

    const isScrollHeightChanged = contentScrollHeight !== prevscrollvalues.contentScrollHeight;
    const isScrolledToBottom = (scrollTop + clientHeight + 1) >= contentScrollHeight;

    if (this.scrollDown && isScrollHeightChanged) {
        this.scrollBottom();
    } else if (!this.scrollDown) {
        this.handleScrollUpLogic(scrollvalues);
    }

    if (isScrolledToBottom) {
        console.log('scroll down');
        this.resetHistory();
    }
  }

  handleScrollUpLogic(scrollvalues) {
      const { scrollTop, scrollHeight } = scrollvalues;

      if (this.lastScrollHeight !== 0) {
          const newScrollHeight = scrollHeight - this.lastScrollHeight;
          this.scrollBar.scrollTo(0, newScrollHeight);
          this.lastScrollHeight = 0;
      } else if (scrollTop === 0 && this.lastScrollHeight === 0) {
          this.lastScrollHeight = scrollHeight;
          this.getHistory(this.getFirstMessageID());
          console.log('scroll top');
      }
  }



  resetHistory() {
      let storeMessages = getGlobal()[this.roomID + "Messages"];
      if (storeMessages.length > this.normalMessageLimit) {
          var currentLength = storeMessages.length;
          if (currentLength >= this.normalMessageLimit) {
            if (this.currentPage < 3) {
              var delLines = currentLength - this.normalMessageLimit;
              setGlobal({
                [this.roomID + "Messages"]: storeMessages.slice(delLines, currentLength)
              });
            } else {
              this.setState({
                rendered: false,
              });
             }
          }
      }
      this.currentPage = 1;
      this.lastHistoryFromID = 0;
  }

  scrollBottom() {
    try {
      this.scrollBar.scrollToBottom();
    } catch (err) {}
  }

  scrollToTop() {
    try {
      this.scrollBar.scrollToTop();
    } catch (err) {}
  }

  loadPreviousMessages() {
    let getScrollState=this.scrollBar.getScrollState();
    this.lastScrollHeight = getScrollState.scrollHeight;

    console.log(getScrollState);
    this.getHistory(this.getFirstMessageID());
  }


  checkMe(message) {
    var re = new RegExp("^/me (.*)", "ig");
    if (re.test(message)) {
      return true;
    } else {
      return false;
    }
  }

  componentDecorator(href, text, key) {
    text = text.length > 130 ? text.substring(0, 130) + "..." : text;
    return (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  getValidUrl = (url = "") => {
    const trimmedUrl = window.decodeURIComponent(url).trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(trimmedUrl)) {
        return `http${trimmedUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(trimmedUrl)) {
        return `http://${trimmedUrl}`;
    }

    return trimmedUrl;
  };

  checkLastUrl = (message) => {
    const urlRegex = require("url-regex");
    const arr = message.match(urlRegex({ strict: false }));
    return arr && arr[0] !== "" ? this.getValidUrl(arr[0]) : "";
  };

  getUrlExtension(url) {
    let path = new URL(url).pathname;
    if (path === "/") {
      return "";
    } else {
      return path.split(/[#?]/)[0].split(".").pop().trim();
    }
  }

  shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return RR+GG+BB;
  }


  //////////////////////////////////////////////////


  getShortTime(unixTimestamp) {
    var dt = new Date(unixTimestamp * 1000);
    var hr = dt.getHours();
    var m = "0" + dt.getMinutes();

    if (hr < 10) {
      hr = "0" + hr;
    }
    return hr + ":" + m.substr(-2);
  }


  formatDate(dateStr) {
    dateStr = dateStr + " 00:00:00";
    var date = new Date(dateStr);
    if (Number.isNaN(date.getMonth())) {
        let arr = dateStr.split(/[- :]/);
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    }
    return date.getTime();
    }

    formatTime(unixTimestamp) {
    let date = new Date(unixTimestamp * 1000);
    var hh = date.getHours().toString();
    var mm = date.getMinutes().toString();
    var dd = date.getDate().toString();
    var year = date.getFullYear();
    var monthIndex = date.getMonth() + 1;

    if (hh < 10) {
        hh = "0" + hh;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (monthIndex < 10) {
        monthIndex = "0" + monthIndex;
    }

    return year + "" + monthIndex + "" + dd + "" + hh + "" + mm;
    }


    formatDateTime(dateStr) {
      let date = new Date(dateStr);
  
      var monthNames = [
          "jan",
          "febr",
          "márc",
          "ápr",
          "máj",
          "jún",
          "júl",
          "aug",
          "szept",
          "okt",
          "nov",
          "dec",
      ];
  
      var dd = date.getDate().toString();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
  
      var hh = date.getHours().toString();
      var mm = date.getMinutes().toString();
  
      var ddChars = dd.split("");
      var hhChars = hh.split("");
      var mmChars = mm.split("");
  
      return (
          year +
          ". " +
          monthNames[monthIndex] +
          ". " +
          (ddChars[1] ? dd : "0" + ddChars[0]) +
          ". " +
          (hhChars[1] ? hh : "0" + hhChars[0]) +
          ":" +
          (mmChars[1] ? mm : "0" + mmChars[0])
      );
      }

      MessageItem(arr, messageClass, index, onlyRender = false) {
        const reactStringReplace = require("react-string-replace");
    
        const lastUser = index?.username || "";
        const previousTime = index?.time || 0;
    
        const shortTime = this.getShortTime(arr.time);
        const humanDateFormat = this.formatDateTime(arr.time * 1000);
        const elapsedTimeNow = Math.floor(Date.now() / 1000) - arr.time;
    
        let me = this.checkMe(arr.message);
        let umessage = me ? arr.message.replace(/^.{4}/, "") : arr.message;
    
        let color = { color: "var(--app-chatroom-default-server-message)" };
        let color2 = {};
    
        if (arr.color) {
          color = { color: `#${arr.color}` };
          if (this.global.themeMode === "light") {
            color = { color: this.shadeColor(`#${arr.color}`, -35) };
          }
        }
    
        let vipColor = null;
        if (arr.vipColor) {
          vipColor = {
            border: `2px solid ${this.global.themeMode === "light" ? this.shadeColor(`#${arr.vipColor}`, -25) : `#${arr.vipColor}`}`,
          };
        }
    
        let embeddedUrl = null;
        if (this.global.user.urlPreview) {
          const lastUrl = this.checkLastUrl(arr.message);
          if (lastUrl && ["jpg", "jpeg", "png", "gif"].includes(this.getUrlExtension(lastUrl))) {
            embeddedUrl = (
              <div className="react_tinylink_fullImage">
                <ReactTinyLink
                  className="fullImage"
                  cardSize="large"
                  proxyUrl={`${this.global.proxyUrl}/${this.global.sid}`}
                  showGraphic={true}
                  url={lastUrl}
                />
              </div>
            );
          }
        }
    
        const processedMessage = this.global.user.smileys
          ? toArray(umessage, { className: "smiley" }).map((i) =>
              typeof i === "string" && !arr.serverMessage
                ? reactStringReplace(
                    i,
                    /@\[[A-Za-z0-9öüóúáéíúűő @_^<>()+\-!=%#*]*\]/gm,
                    (match) =>
                      match === this.global.username &&
                      this.global.user.highlightUsername &&
                      arr.username !== this.global.username ? (
                        <span key={shortid.generate()} className="highlighted">
                          {match}
                        </span>
                      ) : (
                        <span key={shortid.generate()} className="highlighted-normal">
                          {match}
                        </span>
                      )
                  )
                : <Linkify key={shortid.generate()}>{i}</Linkify>
            )
          : [<Linkify key={shortid.generate()}>{umessage}</Linkify>];
    
        if (arr.serverMessage) {
          const messageDate = new Date(arr.time * 1000).toLocaleDateString("hu-HU");
          const day = new Date(previousTime * 1000).toLocaleDateString("hu-HU");
    
          const newDay =
            (this.formatDate(messageDate) > this.formatDate(day) || previousTime === 0) && (
              <div className="seperatorContainer">
                <div className="seperator">{messageDate}</div>
              </div>
            );
    
          return (
            <div className="item" key={arr.id}>
              {newDay}
              <div className="sender">
                <div className="messageData serverMessage">
                  <span className="nick" style={color}>
                    <span className="serverTime">{shortTime}</span> {processedMessage}
                  </span>
                </div>
              </div>
            </div>
          );
        } else {
          const messageDate = new Date(arr.time * 1000).toLocaleDateString("hu-HU");
          const day = new Date(previousTime * 1000).toLocaleDateString("hu-HU");
          const isNewDay = this.formatDate(messageDate) > this.formatDate(day);
          const newDay =
            isNewDay && (
              <div className="seperatorContainer">
                <div className="seperator">{messageDate}</div>
              </div>
            );
    
          const timeLabel = elapsedTimeNow >= 86400 ? humanDateFormat : `${shortTime}-kor`;
          const profileImage = arr.profileImage
            ? { backgroundImage: `url(${this.global.profileImageUrl}/${arr.profileImage}.jpg)` }
            : {};
    
          const lineClass = isNewDay ? "messageData" : "messageData line";
    
          return (
            <div className={`item ${lastUser === arr.username && !onlyRender ? "onlyItem" : "fullItem"}`} key={arr.id}>
              {newDay}
              <div className="sender">
                <div className={lineClass}>
                  <span className="nick" style={color}>
                    <div
                      className="profileImage"
                      style={vipColor}
                      onClick={(e) => this.openProfile(e, arr.username)}
                    >
                      <div style={profileImage}></div>
                    </div>{" "}
                    <span
                      className="username"
                      onClick={(e) => this.openProfile(e, arr.username)}
                    >
                      {arr.username}
                    </span>
                    <span className="time">{timeLabel}</span>
                  </span>
                  <span className={messageClass} style={color2}>
                    {me ? <i>*** {processedMessage}</i> : processedMessage}
                  </span>
                </div>
              </div>
              {embeddedUrl}
            </div>
          );
        }
      }

  //////////////////////////////////////////////////

  messageList() {
    var messages = this.global[this.roomID + "Messages"];
 
    var mainClass = "RoomMessages";
    if (this.global.user.modernView === true) {
      mainClass = "RoomMessages ModernView";
    }

    var messageClass;

    if (getGlobal().user.coloredMessages === false) {
      messageClass = "message noMessageColor";
    } else {
      messageClass = "message";
    }

    return (
      <div className="messagesContainer">
      <Scrollbar
        ref={(e) => (this.scrollBar = e)}
        noScrollX={true}
        noScroll={this.state.noScroll}
        onScroll={this.onScroll.bind()}
        onUpdate={this.scrollUpdate.bind()}
        rtl={false}
      >
        <LightboxWrapper onLightboxStateChange={this.handleLightboxStateChange}>
            <Twemoji
              options={{ className: "twemoji", noWrapper: true }}
              srl_gallery_image="false"
            >
                {this.state.hideHistoryLoadButton === false &&
                <div className="loadButtonContainer">
                  <button
                    onClick={() => this.loadPreviousMessages()}
                    className="loadButton"
                  >
                    Korábbi üzenetek <FontAwesomeIcon icon={faChevronUp} />
                  </button>
                </div>
                }

                <div className="messagePreloader">
                  {this.state.messageLoader === true &&
                    <Circle color={'var(--app-secondary-text-color)'} />
                  }
                </div>

                <div className={mainClass}>
                  {messages.map((arr, index) => {
                    const { id, serverMessage } = arr;
                    const prevMessage = messages[index - 1];

                    // Ellenőrizd, hogy szükséges-e a cache frissítése
                    const shouldUpdateCache =
                      !this.resultCache[id] ||
                      (id === messages[0].id && this.modernView && !serverMessage);

                    if (shouldUpdateCache) {
                      const onlyRender = id === messages[0].id && this.modernView && !serverMessage;
                      this.resultCache[id] = this.MessageItem(arr, messageClass, prevMessage, onlyRender);
                    }

                    return <div key={id}>{this.resultCache[id]}</div>;
                  })}
                </div>
            </Twemoji>
          </LightboxWrapper>
      </Scrollbar>
      </div>
    );
  }


  render() {
    if (this.state.rendered === true) {
      return this.messageList();
    } else {
      return <div>{this.global.selectedTab}</div>;
    }
  }
}
export default RoomMessages;
