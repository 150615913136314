import { getGlobal, setGlobal } from 'reactn';
import shortid from "shortid";

export default class notificationsController {
    constructor(chatClient) {
        this.chatClient = chatClient;
    }

    add(message, show) {
        let notifications = getGlobal().notifications;
        let data = notifications.slice();

        data.unshift({
            id: shortid.generate(),
            message: message,
            time: getGlobal().serverTime,
        })

        if (show === true) {
            this.chatClient.tabController.changeColor('notifications', getGlobal().tabNewNotificationMessageColor);
        }

        setGlobal({
            notifications: data
        })

    }

}