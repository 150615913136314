import React, { PureComponent, setGlobal } from 'reactn';
import { Tabs, TabPanel, Menu, MenuItem } from 'rc-easyui';
import md5 from 'js-md5';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage';
import ContextMenuHandler from "../classes/ContextMenuHandler";
import { List, AutoSizer } from 'react-virtualized';
import shortid from "shortid";

class RoomUsersWithProfileImage extends PureComponent {

	constructor(props) {
		super(props);
		this.client = clientStorage.id;
		this.roomID = this.props.roomID;
		this.state = {
			data: [],
			selected: [],
			selectedName: '',
			ages1: [],
			ages2: [],
			ageMin: 9,
			ageMax: 100,
			contextMenuSelected: '',
			ignoredText: '',
			ignoredCommand: '',
			favoriteText: '',
			favoriteCommand: '',
			search: '',
			rendered:false,
		}

		this.contextMenuHandler = new ContextMenuHandler(
			e => {
				this.menu.showContextMenu(e.pageX, e.pageY)
			}
		);
	}

	handleClickItem(e, name) {
		switch (e.detail) {
		  case 1:
			this.setState({ selected: md5(name), selectedName: name });
			break;
		  case 2:
			this.handleDoubleClickItem(name);
			break;
		  default:
		}
	  }

	componentDidUpdate(prevProps, prevState) {
		if (this.global.selectedTab === this.roomID) {
				if (this.state.rendered === false) {
				this.setState({
					rendered: true,
				});
			}
		}
	}

	componentDidMount() {
		this.genAgeFilter1();
		this.genAgeFilter2();
	}

	handleDoubleClickItem(name) {
		this.client.sendMessage("private_join\x00" + name);
	}

	ageFilterChangeMin = (event) => {
		this.setState({ ageMin: event.target.value });
		this.genAgeFilter2(event.target.value);
	};
	

	ageFilterChangeMax = (event) => {
		this.setState({ ageMax: event.target.value });
	};

	genAgeFilter1() {
		var ret = [];
		var i = 9;
		while (i <= 100) {
		  ret.push({ age: parseInt(i) });
		  i++;
		}
		this.setState({ ages1: ret });
	}
	
	genAgeFilter2(min=9) {
		var ret = [];
		var i = min;
		
		if (min > 9) {
		ret.push({ age: 9 });
		}
		
		while (i <= 100) {
			ret.push({ age: parseInt(i) });
			i++;
		}
		this.setState({ ages2: ret });
	}

	handleContextMenu(name, event) {
		event.preventDefault();
		if (name !== this.global.username) {
			this.handleContextMenuItems(name);
			this.menu.showContextMenu(event.pageX, event.pageY);
		}
	}

	handleTouchMenu(name, event) {
		if (name !== this.global.username) {
			this.handleContextMenuItems(name);
			this.contextMenuHandler.onTouchStart(event);
		}
	}

	handleContextMenuItems(name) {
		let ignoredText = 'Némítás';
		let ignoredCommand = 'ignore';
		if (this.userInList(this.global.ignores, name) === true) {
			ignoredText = 'Némítás levétele';
			ignoredCommand = 'ignoreDelete';
		}

		let favoriteText = 'Felvétel a kedvencek közé';
		let favoriteCommand = 'favorite';
		if (this.userInList(this.global.favorites, name) === true) {
			favoriteText = 'Törlés a kedvencek közül';
			favoriteCommand = 'favoriteDelete';
		}

		this.setState({
			contextMenuSelected: name,
			ignoredText: ignoredText,
			ignoredCommand: ignoredCommand,
			favoriteText: favoriteText,
			favoriteCommand: favoriteCommand
		});
	}

	handleItemClick(value) {
		if (value === "private") {
			this.client.sendMessage("private_join\x00" + this.state.contextMenuSelected);
		} else if (value === "ignore") {
			this.client.sendMessage("add_ignore\x00" + this.state.contextMenuSelected);
		} else if (value === "ignoreDelete") {
			this.client.sendMessage("del_ignore\x00" + this.state.contextMenuSelected);
		} else if (value === "favorite") {
			this.client.sendMessage("add_favorite\x00" + this.state.contextMenuSelected);
		} else if (value === "favoriteDelete") {
			this.client.sendMessage("del_favorite\x00" + this.state.contextMenuSelected);
		} else if (value === "kick") {
			this.client.sendMessage("roommsg\x00" + this.roomID + "\x00/kick " + this.state.contextMenuSelected);
		} else if (value === "kickban") {
			this.client.sendMessage("roommsg\x00" + this.roomID + "\x00/ban " + this.state.contextMenuSelected);
		} else if (value === "info") {
			setGlobal({
				profileModalOpened: true,
				profileModalOpenedUser: this.state.contextMenuSelected
			})
		} else if (value === "moderator") {
			this.handleOpenModeratorModalInMenu(this.state.contextMenuSelected);	
		}
	}

	userInList(data, username) {
		for (var key in data) {
			if (data[key]['name'] === username) {
				return true;
			}
		}
		return false;
	}

	allOnScroll(scrollvalues, prevscrollvalues) {
		const scrollTop = scrollvalues.scrollTop;
		const scrollLeft = scrollvalues.scrollLeft;
		const { Grid: grid } = this.allList;
		grid.handleScrollEvent({ scrollTop, scrollLeft });
	};

	mensOnScroll(scrollvalues, prevscrollvalues) {
		const scrollTop = scrollvalues.scrollTop;
		const scrollLeft = scrollvalues.scrollLeft;
		const { Grid: grid } = this.mensList;
		grid.handleScrollEvent({ scrollTop, scrollLeft });
	};

	womansOnScroll(scrollvalues, prevscrollvalues) {
		const scrollTop = scrollvalues.scrollTop;
		const scrollLeft = scrollvalues.scrollLeft;
		const { Grid: grid } = this.womansList;
		grid.handleScrollEvent({ scrollTop, scrollLeft });
	};


	formChange(event, name) {
		if (name === "search") {
		  this.setState({
			search: event.target.value,
		  });
		}
	}

    handleOpenModeratorModal() {
        setGlobal({ 
			moderatorModalOpened: true,
			moderatorModalOpenedUser: this.state.selectedName,
			moderatorModalOpenedRoom: this.roomID
		 });
    }

    handleOpenModeratorModalInMenu(name) {
        setGlobal({ 
			moderatorModalOpened: true,
			moderatorModalOpenedUser: name,
			moderatorModalOpenedRoom: this.roomID
		 });
    }

	render() {

		if (this.state.rendered === true) {

		var usersUnsorted = this.global[this.roomID + 'Users'];
		// let roomSettings = this.global[this.roomID + 'Settings'];

		var users = usersUnsorted.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});


		const searchRegex = new RegExp(this.state.search, 'ig');
		var all = users.filter(arr => parseInt(arr.age) <= parseInt(this.state.ageMax) && parseInt(arr.age) >= parseInt(this.state.ageMin) && arr.name.match(searchRegex));
		var mens = users.filter(arr => arr.sex === '1' && parseInt(arr.age) <= parseInt(this.state.ageMax) && parseInt(arr.age) >= parseInt(this.state.ageMin) && arr.name.match(searchRegex));
		var womans = users.filter(arr => arr.sex === '2' && parseInt(arr.age) <= parseInt(this.state.ageMax) && parseInt(arr.age) >= parseInt(this.state.ageMin) && arr.name.match(searchRegex));

		const allUser = ({ index, style }) => {
			let arr = all[index];
			let state = arr.state;
			let profileImage;

			if (arr.profileImage !== '') {
				profileImage = {
					backgroundImage: `url(${this.global.profileImageUrl}/${arr.profileImage}.jpg)`,
				};
			}

			if (this.userInList(this.global.favorites, arr.name) === true) {
				if (arr.state === 'disturb') {
					state = 'favoriteDisturb';
				} else {
					state = 'favorite';
				}
			}

			if (this.userInList(this.global.ignores, arr.name) === true) {
				state = 'ignored';
			}

			var itemClass = 'userState-' + state;
			if (this.state.selected === md5(arr.name)) {
				itemClass = 'selected userState-' + state;
			}

			var userIcon;
			if (arr.icon !== "") {
				userIcon = <span className={'userIcon ' + arr.icon + ''} />;
			}

			if (arr.sex === "1") {
				return <li style={style} key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}><span className="icon-userlist" /> <span className="userlist-profileimage"><span style={profileImage} className={state}></span></span> <span className="userlist-age male-colored">{arr.age}</span> {userIcon} {arr.name}</li>
			} else if (arr.sex === "2") {
				return <li style={style} key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}><span className="icon-userlist" /> <span className="userlist-profileimage"><span style={profileImage}></span></span> <span className="userlist-age female-colored">{arr.age}</span> {userIcon} {arr.name}</li>
			} else if (arr.sex === "4") {
				return <li style={style} key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}> <span className="userlist-profileimage"><span style={profileImage}></span></span> <span className="userlist-age">{arr.age}</span> {userIcon} <span className="userlist-closed">{arr.name}</span></li>
			} else {
				return <li style={style} key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}> <span className="userlist-profileimage"><span style={profileImage}></span></span> <span className="userlist-age unknown-colored">{arr.age}</span> {userIcon} {arr.name}</li>
			}
		};

		const onlyMens = ({ index, style }) => {
			let arr = mens[index];
			let state = arr.state;
			let profileImage;

			if (arr.profileImage !== '') {
				profileImage = {
					backgroundImage: `url(${this.global.profileImageUrl}/${arr.profileImage}.jpg)`,
				};
			}

			if (this.userInList(this.global.favorites, arr.name) === true) {
				if (arr.state === 'disturb') {
					state = 'favoriteDisturb';
				} else {
					state = 'favorite';
				}
			}

			if (this.userInList(this.global.ignores, arr.name) === true) {
				state = 'ignored';
			}

			var itemClass = 'userState-' + state;
			if (this.state.selected === md5(arr.name)) {
				itemClass = 'selected userState-' + state;
			}

			var userIcon;
			if (arr.icon !== "") {
				userIcon = <span className={'userIcon ' + arr.icon + ''} />;
			}

			if (arr.sex === "1") {
				return <li style={style} key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}><span className="userlist-profileimage"><span style={profileImage}></span></span> <span className="userlist-age male-colored">{arr.age}</span> {userIcon} {arr.name}</li>
			} else {
				return null;
			}
		};

		const onlyWomans = ({ index, style }) => {
			let arr = womans[index];
			let state = arr.state;
			let profileImage;

			if (arr.profileImage !== '') {
				profileImage = {
					backgroundImage: `url(${this.global.profileImageUrl}/${arr.profileImage}.jpg)`,
				};
			}

			if (this.userInList(this.global.favorites, arr.name) === true) {
				if (arr.state === 'disturb') {
					state = 'favoriteDisturb';
				} else {
					state = 'favorite';
				}
			}

			if (this.userInList(this.global.ignores, arr.name) === true) {
				state = 'ignored';
			}

			var itemClass = 'userState-' + state;
			if (this.state.selected === md5(arr.name)) {
				itemClass = 'selected userState-' + state;
			}

			var userIcon;
			if (arr.icon !== "") {
				userIcon = <span className={'userIcon ' + arr.icon + ''} />;
			}

			if (arr.sex === "2") {
				return <li key={shortid.generate()} onContextMenu={(e) => this.handleContextMenu(arr.name, e)} onTouchStart={(e) => this.handleTouchMenu(arr.name, e)} onTouchCancel={this.contextMenuHandler.onTouchCancel}  onTouchEnd={this.contextMenuHandler.onTouchEnd} onTouchMove={this.contextMenuHandler.onTouchMove} onClick={(e) => this.handleClickItem(e, arr.name)} className={itemClass}><span className="userlist-profileimage"><span style={profileImage}></span></span> <span className="userlist-age female-colored">{arr.age}</span> {userIcon} {arr.name}</li>
			} else {
				return null;
			}
		}

		const listStyle = {
			overflowX: false,
			overflowY: false,
		};

		let classes;
		if (this.global.moderator === true) {
			classes = "chatroom-userlist right-moderator"
		} else {
			classes = "chatroom-userlist"
		}

		return (
			<div className={classes}>
				<Tabs>
					<TabPanel title="" iconCls="icon-userlist-users">
						<div className="chatContainer">
							<div className="ageFilter">
								<select onChange={this.ageFilterChangeMin} value={this.state.ageMin}>
									{
										this.state.ages1.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={arr.age} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -tól
								<select onChange={this.ageFilterChangeMax} value={this.state.ageMax}>
									{
										this.state.ages2.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={100} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -ig
							</div>

							<div className="search">
								<input type="text" name="search" value={this.state.search} onChange={(e) => this.formChange(e, "search")} placeholder="Keresés..."></input>
							</div>

							{/* {this.global.showRoomUserlist === true && */}
							<Scrollbar noScrollX={true} onScroll={this.allOnScroll.bind(this)} >
								<AutoSizer>
									{({ height, width }) => (
										<ul>
											<List
												className="List"
												height={height}
												rowCount={all.length}
												rowHeight={36}
												rowRenderer={allUser}
												width={width}
												ref={instance => (this.allList = instance)}
												style={listStyle}
											/>
										</ul>
									)}
								</AutoSizer>
								{all.length === 0 &&
									<p className="not-found">Nincs találat!</p>
								}
							</Scrollbar>
							{/* } */}
							{all.length > 0 &&
								<div className="userCounter">{all.length} felhasználó</div>
							}
						</div>
					</TabPanel>
					<TabPanel title="" iconCls="icon-userlist-male">
						<div className="chatContainer">
							<div className="ageFilter">
								<select onChange={this.ageFilterChangeMin} value={this.state.ageMin}>
									{
										this.state.ages1.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={arr.age} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -tól
								<select onChange={this.ageFilterChangeMax} value={this.state.ageMax}>
									{
										this.state.ages2.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={100} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -ig
							</div>

							<div className="search">
								<input type="text" name="search" value={this.state.search} onChange={(e) => this.formChange(e, "search")} placeholder="Keresés..."></input>
							</div>

							{/* {this.global.showRoomUserlist === true && */}
							<Scrollbar noScrollX={true} onScroll={this.mensOnScroll.bind(this)} >
								{this.global.reconnect === false &&
									<AutoSizer>
										{({ height, width }) => (
											<ul>
												<List
													className="List"
													height={height}
													rowCount={mens.length}
													rowHeight={36}
													rowRenderer={onlyMens}
													width={width}
													ref={instance => (this.mensList = instance)}
													style={listStyle}
												/>
											</ul>
										)}
									</AutoSizer>
								}
								{mens.length === 0 &&
									<p className="not-found">Nincs találat!</p>
								}
							</Scrollbar>
							{/* } */}
							{mens.length > 0 &&
								<div className="userCounter">{mens.length} felhasználó</div>
							}
						</div>
					</TabPanel>
					<TabPanel title="" iconCls="icon-userlist-female">
						<div className="chatContainer">
							<div className="ageFilter">
								<select onChange={this.ageFilterChangeMin} value={this.state.ageMin}>
									{
										this.state.ages1.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={arr.age} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -tól
								<select onChange={this.ageFilterChangeMax} value={this.state.ageMax}>
									{
										this.state.ages2.map((arr, index) => {
											if (arr.age < 100) {
												if (arr.age === 9) {
													return <option value={100} key={index}>--</option>
												} else {
													return <option value={arr.age} key={index}>{arr.age}</option>
												}
											} else {
												return null;
											}
										})
									}
								</select> -ig
							</div>

							<div className="search">
								<input type="text" name="search" value={this.state.search} onChange={(e) => this.formChange(e, "search")} placeholder="Keresés..."></input>
							</div>

							{/* {this.global.showRoomUserlist === true && */}
							<Scrollbar noScrollX={true} onScroll={this.womansOnScroll.bind(this)} >
								{this.global.reconnect === false &&
									<AutoSizer>
										{({ height, width }) => (
											<ul>
												<List
													className="List"
													height={height}
													rowCount={womans.length}
													rowHeight={36}
													rowRenderer={onlyWomans}
													width={width}
													ref={instance => (this.womansList = instance)}
													style={listStyle}
												/>
											</ul>
										)}
									</AutoSizer>
								}
								{womans.length === 0 &&
									<p className="not-found">Nincs találat!</p>
								}
							</Scrollbar>
							{/* } */}
							{womans.length > 0 &&
								<div className="userCounter">{womans.length} felhasználó</div>
							}
						</div>
					</TabPanel>
				</Tabs>

				{this.global.moderator === true && 
					<div className="moderator"><button onClick={(e) => this.handleOpenModeratorModal()} ><span className="buttonIcon icon-moderator" title="Moderálás"></span> Moderálás</button></div>
				}

				{this.global.moderator === false &&		
					<Menu noline ref={ref => this.menu = ref} onItemClick={this.handleItemClick.bind(this)}>
						<MenuItem text={this.state.contextMenuSelected} className="owner" disabled></MenuItem>
						<MenuItem value="private" text="Privát üzenet" iconCls="icon-private"></MenuItem>
						<MenuItem value="info" text="Adatlap megtekintése" iconCls="icon-info"></MenuItem>
						<MenuItem value={this.state.favoriteCommand} text={this.state.favoriteText} iconCls="icon-star-simple"></MenuItem>
						<MenuItem value={this.state.ignoredCommand} text={this.state.ignoredText} iconCls="icon-ignore"></MenuItem>
					</Menu>
				}

				{this.global.moderator === true &&
					<Menu noline ref={ref => this.menu = ref} onItemClick={this.handleItemClick.bind(this)}>
						<MenuItem text={this.state.contextMenuSelected} className="owner" disabled></MenuItem>
						<MenuItem value="private" text="Privát üzenet" iconCls="icon-private"></MenuItem>
						<MenuItem value="info" text="Adatlap megtekintése" iconCls="icon-info"></MenuItem>
						<MenuItem value="moderator" text="Moderálás" iconCls="icon-kickban"></MenuItem>
						<MenuItem value={this.state.favoriteCommand} text={this.state.favoriteText} iconCls="icon-star-simple"></MenuItem>
						<MenuItem value={this.state.ignoredCommand} text={this.state.ignoredText} iconCls="icon-ignore"></MenuItem>
					</Menu>
				}
			</div>
		);
		} else {
			return <div>{this.global.selectedTab}</div>;
		}
	}

}
export default RoomUsersWithProfileImage;